import { Component, createEffect, createSignal } from 'solid-js';
import {
  Designer,
  DesignerRegistry,
  DesignerIdGenerator,
} from '@repo/designer';
import { BaseModal } from './BaseModal.jsx';
import { ImagePreview } from '@repo/ui/ImagePreview';
import { Logger } from '@repo/logger';
import { ModalManagerStore } from './ModalManager.store.js';

const logger = new Logger('PreviewModal');

interface PreviewModalProps {
  designer?: Designer;
  onClose: () => void;
  title?: string;
  description?: string;
}

export const PreviewModal: Component<PreviewModalProps> = (props) => {
  const store = ModalManagerStore.getInstance();
  const [previewImage, setPreviewImage] = createSignal<HTMLImageElement | null>(
    null,
  );
  const [isLoading, setIsLoading] = createSignal(true);

  // Load image immediately when designer is ready
  const loadPreviewImage = async (designer: Designer) => {
    try {
      setIsLoading(true);
      logger.info('Starting image load from designer');

      const startTime = performance.now();
      const image = await designer.getPreviewImage();
      const loadTime = performance.now() - startTime;

      logger.info('Image loaded from designer', {
        loadTimeMs: loadTime,
        dimensions: image ? `${image.width}x${image.height}` : 'no image',
      });

      if (!image) {
        throw new Error('No preview image returned from designer');
      }

      // Pre-warm the image cache
      const warmupPromise = new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          logger.info('Image cache warmed', {
            naturalWidth: img.naturalWidth,
            naturalHeight: img.naturalHeight,
          });
          resolve(img);
        };
        img.onerror = reject;
        img.src = image.src;
      });

      const warmedImage = await warmupPromise;
      setPreviewImage(warmedImage);
    } catch (error) {
      logger.error('Failed to load preview:', error);
      // On error, revert to placeholder instead of throwing
      setPreviewImage(null);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle modal visibility changes
  createEffect(() => {
    const isVisible = store.isModalOpen('MODAL_PREVIEW');
    if (isVisible) {
      logger.info('Modal opened - initializing preview');

      // Reset to placeholder while loading
      setPreviewImage(null);
      setIsLoading(true);

      // Get designer instance
      const registry = DesignerRegistry.getInstance();
      const designerId = DesignerIdGenerator.create('main-designer');
      const designer = registry.getInstance(designerId) ?? props.designer;

      if (!designer) {
        logger.error('No designer instance found');
        setIsLoading(false);
        return;
      }

      // Load preview image
      loadPreviewImage(designer).catch((error) => {
        logger.error('Failed to initialize preview:', error);
        setIsLoading(false);
      });
    }
  });

  return (
    <BaseModal
      key="MODAL_PREVIEW"
      title={props.title ?? 'Preview Your Band'}
      onClose={props.onClose}
      description={props.description ?? 'Preview your band designs'}
      size="large"
    >
      <div class="relative w-full h-[80vh]">
        <ImagePreview
          image={previewImage() ?? 'assets/images/dark_wrsr_universe.webp'}
          width="100%"
          height="100%"
          background="#f3f4f6"
          controls={{
            show: true,
            position: 'bottom',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: '#ffffff',
          }}
        />

        {/* Loading State */}
        {isLoading() && (
          <div class="absolute inset-0 flex items-center justify-center bg-gray-50/75">
            <div class="flex flex-col items-center gap-4">
              <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-primary" />
              <p class="text-gray-600">Loading preview...</p>
            </div>
          </div>
        )}
      </div>
    </BaseModal>
  );
};
