import { Component, createSignal, Show } from 'solid-js';
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableHeader,
} from '~/components/ui/table';
import {
  Pagination,
  PaginationEllipsis,
  PaginationItem,
  PaginationItems,
  PaginationNext,
  PaginationPrevious,
} from '~/components/ui/pagination';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '~/components/ui/dialog';
import { ImageLoader } from '~/components/images/ImageLoader';
import { formatBytes, formatDate } from '@repo/shared';

// Define the base cache entry type that matches the common properties
interface BaseCacheEntry {
  key: string;
  type: string;
  size: number;
  timestamp: number;
  expiresAt: number;
}

// Extend for image-specific properties
interface ImageCacheEntry extends BaseCacheEntry {
  fileExt?: {
    width: number;
    height: number;
    aspectRatio: number;
  };
  originalType: string;
  originalSize?: number;
  lastAccess?: number;
}

interface ImageCacheTableProps {
  data: ImageCacheEntry[];
  pageSize?: number;
  onClearItem: (key: string) => Promise<void>;
}

export const ImageCacheTable: Component<ImageCacheTableProps> = (props) => {
  const [currentPage, setCurrentPage] = createSignal(1);
  const [selectedImage, setSelectedImage] =
    createSignal<ImageCacheEntry | null>(null);

  const pageSize = () => props.pageSize || 5;
  const totalPages = () => Math.ceil(props.data.length / pageSize());
  const startIndex = () => (currentPage() - 1) * pageSize();
  const endIndex = () => Math.min(startIndex() + pageSize(), props.data.length);
  const currentData = () => props.data.slice(startIndex(), endIndex());

  // Custom item component that matches the expected type
  const PaginationItemComponent: Component<{ page: number }> = (props) => (
    <PaginationItem
      page={props.page}
      onClick={(e: Event) => {
        e.preventDefault();
        setCurrentPage(props.page);
      }}
    >
      {props.page}
    </PaginationItem>
  );

  // Custom ellipsis component with correct return type
  const EllipsisComponent = () => <PaginationEllipsis />;

  // Handle pagination change events
  const handlePaginationChange = (e: Event) => {
    const target = e.target as HTMLElement;
    const page = Number(target.getAttribute('data-page'));
    if (!isNaN(page)) {
      setCurrentPage(page);
    }
  };

  return (
    <div class="flex flex-col">
      {' '}
      {/* Fixed height container with flex column  h-[600px] */}
      <div class="flex-1 overflow-auto">
        {' '}
        {/* Table container that can scroll */}
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Image</TableHead>
              <TableHead>Type / Original</TableHead>
              <TableHead>Size / Original</TableHead>
              <TableHead>Dimensions</TableHead>
              <TableHead>Created</TableHead>
              <TableHead>Expires</TableHead>
              <TableHead>Last Access</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {currentData().map((entry) => (
              <TableRow>
                <TableCell class="font-mono text-xs">
                  <ImageLoader
                    size="sm"
                    imageKey={entry.key}
                    alt={entry.key}
                    onclick={() => setSelectedImage(entry)}
                  />
                </TableCell>
                <TableCell>
                  {entry.type} / {entry.originalType}
                </TableCell>
                <TableCell>
                  {formatBytes(entry.size)} /{' '}
                  {entry.originalSize ? formatBytes(entry.originalSize) : 'N/A'}
                </TableCell>
                <TableCell>
                  {entry.fileExt
                    ? `${entry.fileExt.width}×${entry.fileExt.height} (${entry.fileExt.aspectRatio.toFixed(2)})`
                    : 'N/A'}
                </TableCell>
                <TableCell>{formatDate(entry.timestamp)}</TableCell>
                <TableCell>{formatDate(entry.expiresAt)}</TableCell>
                <TableCell>
                  {entry.lastAccess ? formatDate(entry.lastAccess) : 'Never'}
                </TableCell>
                <TableCell>
                  <Button
                    variant="destructive"
                    size="sm"
                    onClick={() => props.onClearItem(entry.key)}
                  >
                    Clear
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div class="mt-4 flex justify-center border-t pt-4">
        {' '}
        {/* Fixed pagination container */}
        <Pagination
          count={totalPages()}
          page={currentPage()}
          onChange={handlePaginationChange}
          itemComponent={PaginationItemComponent}
          ellipsisComponent={EllipsisComponent}
        >
          <PaginationPrevious
            onClick={(e: Event) => {
              e.preventDefault();
              if (currentPage() > 1) {
                setCurrentPage(currentPage() - 1);
              }
            }}
            disabled={currentPage() === 1}
          />
          <PaginationItems />
          <PaginationNext
            onClick={(e: Event) => {
              e.preventDefault();
              if (currentPage() < totalPages()) {
                setCurrentPage(currentPage() + 1);
              }
            }}
            disabled={currentPage() === totalPages()}
          />
        </Pagination>
      </div>
      <Dialog
        open={!!selectedImage()}
        onOpenChange={() => setSelectedImage(null)}
      >
        <DialogContent class="max-w-4xl z-50">
          <DialogHeader>
            <DialogTitle>Image Preview - {selectedImage()?.key}</DialogTitle>
          </DialogHeader>
          <Show when={selectedImage()}>
            <ImageLoader
              imageKey={selectedImage()!.key}
              alt={selectedImage()!.key}
              size="full"
            />
          </Show>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ImageCacheTable;
