import { Component, createSignal, onCleanup, Show } from 'solid-js';
import { BaseModal } from './BaseModal.jsx';
// import { Loading } from '../../components/Loading';

interface WorkingModalProps {
  onClose?: () => boolean | undefined | void;
}

export const WorkingModal: Component<WorkingModalProps> = (props) => {
  const [progress, setProgress] = createSignal(0);
  const [currentStep, setCurrentStep] = createSignal(0);

  const steps = [
    'Preparing your design...',
    'Optimizing images...',
    'Generating preview...',
    'Almost there...',
  ];

  // Simulate progress updates
  const progressInterval = setInterval(() => {
    setProgress((prev) => {
      const next = Math.min(prev + 1, 100);
      if (next % 25 === 0) {
        setCurrentStep(Math.floor(next / 25));
      }
      return next;
    });
  }, 50);

  onCleanup(() => clearInterval(progressInterval));

  return (
    <BaseModal
      key="MODAL_WORKING"
      title="Creating Your Band"
      description="Please wait while we process your design"
      size="small"
      isSystemModal={true}
    >
      <div class="p-8 space-y-6">
        <div class="relative pt-1">
          {/* Progress bar */}
          <div class="overflow-hidden h-2 text-xs flex rounded-full bg-gray-200">
            <div
              style={{ width: `${progress()}%` }}
              class="transition-all duration-300 ease-out shadow-none 
                                   flex flex-col text-center whitespace-nowrap 
                                   text-white justify-center bg-yellow-400"
            />
          </div>

          {/* Progress percentage */}
          <div class="text-center mt-4">
            <span class="text-2xl font-bold text-yellow-400">
              {progress()}%
            </span>
          </div>
        </div>

        {/* Step indicator */}
        <div class="text-center space-y-2">
          <p class="text-lg font-medium text-gray-700">
            {steps[currentStep()]}
          </p>
          <div class="flex justify-center space-x-2">
            {steps.map((_, index) => (
              <div
                class={`w-2 h-2 rounded-full transition-all duration-300 ${
                  index <= currentStep()
                    ? 'bg-yellow-400 scale-110'
                    : 'bg-gray-300'
                }`}
              />
            ))}
          </div>
        </div>

        {/* Loading animation */}
        <div class="flex justify-center">
          <div class="relative w-16 h-16">
            <div class="absolute top-0 left-0 w-full h-full">
              <div class="w-full h-full rounded-full border-4 border-yellow-200 animate-pulse" />
            </div>
            <div class="absolute top-0 left-0 w-full h-full">
              <div class="w-full h-full rounded-full border-4 border-yellow-400 border-t-transparent animate-spin" />
            </div>
          </div>
        </div>

        {/* Cancel button (if needed) */}
        <Show when={progress() < 100}>
          <button
            onClick={props.onClose}
            class="text-sm text-gray-500 hover:text-gray-700 
                               transition-colors duration-200"
          >
            Cancel
          </button>
        </Show>
      </div>
    </BaseModal>
  );
};
