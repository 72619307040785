import { Component, createSignal, onCleanup, onMount } from 'solid-js';
import { isServer } from 'solid-js/web';
import { ActionButton } from './ActionButton';
import {
  ActionType,
  ActionConfig,
  Managers,
  GestureDetails,
} from './ActionButtonTypes';
import { initializeManagers } from './managers';
import { Logger } from '@repo/logger';

interface ActionButtonsContainerProps {
  /* onRotate?: () => void; */
  onWelcome?: () => void;
  onPreview?: () => void;
  onPurchase?: () => void;
}

const actionConfigs: Record<ActionType, ActionConfig> = {
  /* rotate: {
    iconType: 'rotate',
    label: 'Rotate Design',
    sound: 'rotate',
    colorClasses: {
      base: 'bg-blue-500/90 dark:bg-blue-600/90 text-white',
      hover: 'hover:bg-blue-600 dark:hover:bg-blue-500',
      active: 'active:bg-blue-700 dark:active:bg-blue-400',
      selected:
        'bg-blue-600 dark:bg-blue-400 ring-2 ring-blue-300 dark:ring-blue-200',
    },
    particleEffect: 'SPIRALIZER',
  }, */
  welcome: {
    iconType: 'welcome',
    label: 'Designer How To',
    colorClasses: {
      base: 'bg-[#85e0f6] text-white',
      hover: 'hover:bg-[#58deff]',
      active: 'active:bg-[#23cff9]',
      selected: 'bg-[#57deff] ring-2 ring-[#aceefe]',
    },
  },
  preview: {
    iconType: 'eye',
    label: 'Preview Design',
    sound: 'preview',
    colorClasses: {
      base: 'bg-[#42B692] not-dark:bg-emerald-600/90 text-white',
      hover: 'hover:bg-[#42b692e6] not-dark:hover:bg-emerald-500',
      active: 'active:bg-[#27af85] not-dark:active:bg-emerald-400',
      selected:
        'bg-[#42b692e6] not-dark:bg-emerald-400 ring-2 ring-emerald-300 not-dark:ring-emerald-200',
    },
    /* particleEffect: 'BURSTER', */
  },
  purchase: {
    iconType: 'cart',
    label: 'Save Design',
    sound: 'purchase',
    colorClasses: {
      base: 'bg-[#FF68EC] not-dark:bg-fuchsia-600/90 text-white',
      hover: 'hover:bg-[#ff68ece6] not-dark:hover:bg-fuchsia-500',
      active: 'active:bg-[#f946e2] not-dark:active:bg-fuchsia-400',
      selected:
        'bg-[#ff68ece6] not-dark:bg-fuchsia-400 ring-2 ring-fuchsia-300 not-dark:ring-fuchsia-200',
    },
    /* particleEffect: 'FOUNTAIN', */
  },
};

export const ActionButtonsContainer: Component<ActionButtonsContainerProps> = (
  props,
) => {
  const logger = new Logger('ActionButtonsContainer');
  let initializationTimeout: number;
  const [managers, setManagers] = createSignal<Managers | null>(null);
  const [containerRef, setContainerRef] = createSignal<HTMLElement>();
  const [isDesktop, setIsDesktop] = createSignal(true);
  const [isInitialized, setIsInitialized] = createSignal(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = createSignal(false);
  const [activeButton, setActiveButton] = createSignal<ActionType | null>(null);

  /* const startHoverParticles = (
    action: ActionType,
    e: MouseEvent | TouchEvent,
  ) => {
    if (!isInitialized() || hoverParticleIntervals()[action]) return;

    const currentManagers = managers();
    if (!currentManagers?.particle) return;

    const { particleEffect } = actionConfigs[action];
    const effect = currentManagers.particle[particleEffect];

    if (effect) {
      currentManagers.particle.createFromEvent(effect, e);

      const interval = window.setInterval(() => {
        if (effect) {
          currentManagers.particle?.createFromEvent(effect, e);
        }
      }, 400) as number;

      setHoverParticleIntervals((prev) => ({ ...prev, [action]: interval }));
    }
  }; */

  /* const stopHoverParticles = (action: ActionType) => {
    const interval = hoverParticleIntervals()[action];
    if (interval) {
      clearInterval(interval);
      setHoverParticleIntervals((prev) => ({ ...prev, [action]: undefined }));
    }
  }; */

  const handleGesture = (details: GestureDetails) => {
    if (!isInitialized()) return;

    const { direction, velocity } = details;
    if (velocity < 0.5) return;

    const actionMap: Record<string, ActionType> = {
      up: 'purchase',
      left: 'preview',
      /* right: 'rotate', */
    };

    const action = actionMap[direction];
    if (action) {
      handleButtonAction(action);
    }
  };

  const handleButtonAction = (action: ActionType) => {
    logger.debug('ActionButtonsContainer: handleButtonAction', { action });
    if (!isInitialized()) return;

    const currentManagers = managers();
    if (!currentManagers?.sound) return;

    // Trigger haptic feedback on mobile
    if (!isDesktop() && currentManagers.device) {
      currentManagers.device.requestHapticFeedback([10, 20]);
    }

    if (actionConfigs[action].sound) currentManagers.sound.play(actionConfigs[action].sound);
    setActiveButton(action);

    // Close mobile menu after action
    if (!isDesktop()) {
      setIsMobileMenuOpen(false);
    }

    const actionHandlers: Record<ActionType, (() => void) | undefined> = {
      /* rotate: props.onRotate, */
      welcome: props.onWelcome,
      preview: props.onPreview,
      purchase: props.onPurchase,
    };

    actionHandlers[action]?.();

    // Reset active button after a short delay
    setTimeout(() => setActiveButton(null), 300);
  };

  const handleInteraction = (
    action: ActionType,
    e: MouseEvent | TouchEvent,
    isHoverStart = false,
  ) => {
    if (!isInitialized()) return;

    const currentManagers = managers();
    if (!currentManagers?.particle) return;

    // const { particleEffect } = actionConfigs[action];
    // const effect = currentManagers.particle[particleEffect];

    // if (effect) {
    //   currentManagers.particle.createFromEvent(effect, e);
    // }

    // Handle mobile-specific interactions
    if ('touches' in e && currentManagers.device) {
      currentManagers.device.requestHapticFeedback([5, 10]);
    }
  };

  onMount(async () => {
    if (isServer) return;

    initializationTimeout = window.setTimeout(async () => {
      const loadedManagers = await initializeManagers();
      if (!loadedManagers) return;

      setManagers(loadedManagers);
      setIsDesktop(loadedManagers.device?.isDesktop() ?? true);

      const container = containerRef();
      if (container && loadedManagers.gesture) {
        loadedManagers.gesture.initialize(container);

        if (loadedManagers.device) {
          const deviceCleanup = loadedManagers.device.onDeviceChange(
            (newIsDesktop) => {
              setIsDesktop(newIsDesktop);
              setIsMobileMenuOpen(false);
            },
          );
          onCleanup(deviceCleanup);
        }

        const gestureCleanup = loadedManagers.gesture.onGesture(handleGesture);
        onCleanup(gestureCleanup);
      }

      setIsInitialized(true);
    }, 0);
  });

  onCleanup(() => {
    if (initializationTimeout) {
      clearTimeout(initializationTimeout);
    }
  });

  return (
    <nav
      ref={setContainerRef}
      class={`
        fixed z-50 transition-all duration-300 ease-in-out
        ${
          isDesktop()
            ? 'bottom-8 right-8'
            : `bottom-8 right-4`
        }
      `}
    >
      <div
        class={`
          flex items-center gap-4
          ${
            /* isDesktop()
              ?  */'flex-col'
              /* : 'flex-row justify-around p-4 bg-white/80 dark:bg-black/80 backdrop-blur-lg border-t border-gray-200 dark:border-gray-800' */
          }
        `}
      >
        {/* Mobile Menu Toggle */}
        {/* {!isDesktop() && (
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen())}
            class="absolute -top-12 left-1/2 -translate-x-1/2 w-12 h-12 bg-gray-900/80 dark:bg-gray-100/80 rounded-full flex items-center justify-center backdrop-blur-lg"
          >
            <div
              class={`w-6 h-1 bg-white dark:bg-gray-900 rounded-full transition-transform ${isMobileMenuOpen() ? 'rotate-45' : ''}`}
            />
            <div
              class={`w-6 h-1 bg-white dark:bg-gray-900 rounded-full transition-opacity ${isMobileMenuOpen() ? 'opacity-0' : ''} absolute`}
            />
            <div
              class={`w-6 h-1 bg-white dark:bg-gray-900 rounded-full transition-transform ${isMobileMenuOpen() ? '-rotate-45' : ''}`}
            />
          </button>
        )} */}

        {/* Action Buttons */}
        {(Object.keys(actionConfigs) as ActionType[]).map((type) => (
          <ActionButton
            type={type}
            config={actionConfigs[type]}
            isInitialized={isInitialized()}
            managers={managers()}
            isDesktop={isDesktop()}
            isSelected={activeButton() === type}
            onAction={() => handleButtonAction(type)}
            onInteraction={(e, isHoverStart) =>
              handleInteraction(type, e, isHoverStart)
            }
          />
        ))}
      </div>
    </nav>
  );
};

export default ActionButtonsContainer;
