// import { JSX } from 'solid-js/jsx-runtime';
import { BaseModal } from './BaseModal';
import { CacheMonitor } from '~/features/dev/components/cache/CacheMonitor';

interface CacheMonitorProps {
  // children: JSX.Element;
  onClose: () => void;
}

export const CacheMonitorModal = (props: CacheMonitorProps) => {
  return (
    <BaseModal
      key="MODAL_DEV_CACHE_MONITOR"
      title="Cache Monitor"
      description="Manage cache entries"
      size="full"
    >
      {/* {props.children}  */}
      <CacheMonitor onClose={props.onClose} />
    </BaseModal>
  );
};
