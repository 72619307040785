import { Component, createSignal, createEffect, onCleanup } from 'solid-js';
import { Database } from '@repo/state-manager';
import { IDB, imagecache } from '@repo/database';

export interface ImageLoaderProps {
  imageKey: string;
  alt?: string;
  className?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full' | 'custom';
  customClass?: string;
  onclick?: () => void;
}

export const sizeClasses = {
  sm: 'w-24 h-24',
  md: 'w-32 h-32',
  lg: 'w-48 h-48',
  xl: 'w-64 h-64',
  '2xl': 'w-96 h-96',
  full: 'w-full h-auto',
  custom: '', // Will use customClass instead
};

export const ImageLoader: Component<ImageLoaderProps> = (props) => {
  const [src, setSrc] = createSignal<string | null>(null);
  const [error, setError] = createSignal<string | null>(null);
  const [loading, setLoading] = createSignal(true);

  let db: IDB;
  let ic: imagecache.ImageCache;

  const cacheImage = async (key: string) => {
    const image = new Image();
    try {
      const database = Database.getInstance();
      await database.initialize();
      db = database.db;
      ic = database.imageCache;

      const { meta, buffer } = await ic.getData(key);
      const blob = new Blob([buffer], { type: meta.type });
      const objectUrl = URL.createObjectURL(blob);
      image.src = objectUrl;

      if (meta.fileExt !== undefined) {
        image.width = meta.fileExt.width;
        image.height = meta.fileExt.height;
      }

      return new Promise<string>((resolve, reject) => {
        image.onload = () => resolve(objectUrl);
        image.onerror = () => reject(new Error('Failed to load image'));
      });
    } catch (err) {
      throw new Error(
        err instanceof Error ? err.message : 'Failed to load image data',
      );
    }
  };

  const getImageClasses = () => {
    const baseClasses = 'object-cover';
    if (props.size === 'custom' && props.customClass) {
      return `${baseClasses} ${props.customClass}`;
    }
    const sizeClass = props.size ? sizeClasses[props.size] : sizeClasses.md;
    return `${baseClasses} ${sizeClass} ${props.className || ''}`;
  };

  createEffect(() => {
    let mounted = true;

    const loadImage = async () => {
      try {
        setLoading(true);
        setError(null);
        const imageUrl = await cacheImage(props.imageKey);
        if (mounted) {
          setSrc(imageUrl);
        }
      } catch (err) {
        if (mounted) {
          setError(
            err instanceof Error ? err.message : 'Unknown error occurred',
          );
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    loadImage();

    onCleanup(() => {
      mounted = false;
      if (src()) {
        URL.revokeObjectURL(src()!);
      }
    });
  });

  const LoadingPlaceholder = () => (
    <div
      class={`${getImageClasses()} bg-gray-200 animate-pulse flex items-center justify-center`}
    >
      <div class="text-gray-400">Loading...</div>
    </div>
  );

  const ErrorDisplay = () => (
    <div
      class={`${getImageClasses()} bg-red-50 flex items-center justify-center`}
    >
      <div class="text-red-500 text-sm p-2 text-center">{error()}</div>
    </div>
  );

  return (
    <>
      {loading() && <LoadingPlaceholder />}
      {error() && <ErrorDisplay />}
      {src() && !error() && (
        <img
          src={src()!}
          alt={props.alt || ''}
          class={getImageClasses()}
          onclick={props.onclick}
        />
      )}
    </>
  );
};

export default ImageLoader;
