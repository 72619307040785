import { Logger } from '@repo/logger';

export class PerformanceMonitor {
  static #instance: PerformanceMonitor;
  #logger: Logger;

  static getInstance(): PerformanceMonitor {
    if (!PerformanceMonitor.#instance) {
      PerformanceMonitor.#instance = new PerformanceMonitor();
    }
    return PerformanceMonitor.#instance;
  }

  constructor() {
    this.#logger = new Logger('PerformanceMonitor');
  }

  measureInteraction(name: string, callback: () => void) {
    const start = performance.now();
    callback();
    const duration = performance.now() - start;

    this.#logger.info(`Performance measurement: ${name}`, { duration });
  }

  markAndMeasure(markName: string, measureName: string) {
    performance.mark(markName);
    return {
      end: () => {
        performance.measure(measureName, markName);
        const measure = performance.getEntriesByName(measureName).pop();
        if (measure) {
          this.#logger.info(`Performance measure: ${measureName}`, {
            duration: measure.duration,
          });
        }
      },
    };
  }
}
