import { Component, createSignal, Show } from 'solid-js';
import { BaseModal } from './BaseModal.jsx';
import { Analytics } from '../../utils/Analytics.js';
import { ModalManagerStore } from './ModalManager.store';
// import { appStore } from '~/store';

export type OnCloseHandler = () => boolean | undefined | void;

interface WelcomeModalProps {
  onClose: OnCloseHandler;
}

export const WelcomeModal: Component<WelcomeModalProps> = (props) => {
  const analytics = Analytics.getInstance();
  const store = ModalManagerStore.getInstance();
  const [activeStep, setActiveStep] = createSignal(0);

  store.openModal('MODAL_WELCOME');

  const steps = [
    {
      preTitle: 'step 1',
      title: 'Prepare Your Photos',
      description:
        'Edit, filter, or crop the photos in your album before uploading.',
      src: 'https://images.wrsr.io/v1/5b4be305-e825-46c6-8a6e-4af341895a37.webp',
      backgroundColor: '#FBFF53',
    },
    {
      preTitle: 'step 2',
      title: 'Upload Photos',
      description: 'Tap each square in the Band Designer to add your photos.',
      src: 'https://images.wrsr.io/v1/eed5d089-8493-4a08-9e91-a7949dc56bfa.webp',
      backgroundColor: '#85E0F6',
    },
    {
      preTitle: 'step 3',
      title: 'Save & Customize',
      description: 'Name your band, add a secret message, and save.',
      src: 'https://images.wrsr.io/v1/02e9aa7c-4f6d-4b8b-9388-87f57a6782b9.webp',
      backgroundColor: '#A6ABFF',
    },
    {
      preTitle: 'step 4',
      title: 'Purchase or keep creating ',
      description: 'Order your band or continue creating for free.',
      src: 'https://images.wrsr.io/v1/c3ae953f-4d37-44da-a280-8100fbd73467.webp',
      backgroundColor: '#FF68EC',
    },
  ];

  const handleGetStarted = () => {
    analytics.trackEvent({
      category: 'Onboarding',
      action: 'WelcomeModalClosed',
      metadata: {
        stepsViewed: activeStep(),
      },
    });
    store.closeModal();
    // props.onClose?.();
  };

  const handleNextStep = () => {
    if (activeStep() < steps.length - 1) {
      setActiveStep((prev) => prev + 1);
      analytics.trackEvent({
        category: 'Onboarding',
        action: 'StepViewed',
        metadata: { step: activeStep() + 1 },
      });
    } else {
      handleGetStarted();
    }
  };

  const handleCurrentStep = (current: number) => {
    setActiveStep(current);
    analytics.trackEvent({
      category: 'Onboarding',
      action: 'StepViewed',
      metadata: { step: current },
    });
  };

  return (
    <BaseModal
      key="MODAL_WELCOME"
      title="Welcome to Wearshare Band Designer"
      onClose={props.onClose}
      description="Create your custom band in just a few steps"
      size="medium"
      bgColor={steps[activeStep()]?.backgroundColor}
    >
      <div class="space-y-8 py-4">
        {/* Progress indicator */}
        <div class="flex justify-center space-x-2">
          {steps.map((_, index) => (
            <div
              class={`h-1.5 rounded-full transition-all duration-300 cursor-pointer ${
                index <= activeStep() ? 'w-8 bg-black' : 'w-4 bg-gray-200'
              }`}
              onClick={() => handleCurrentStep(index)}
            />
          ))}
        </div>

        {/* Step content with animation */}
        <div class="relative h-[250px]">
          {steps.map((step, index) => (
            <div
              class={`absolute inset-0 transition-all duration-500 transform
                                ${
                                  index === activeStep()
                                    ? 'translate-x-0 opacity-100'
                                    : index < activeStep()
                                      ? '-translate-x-full opacity-0'
                                      : 'translate-x-full opacity-0'
                                }`}
            >
              <div class="text-center space-y-4">
                <h4 class="text-xs font-bold">{step.preTitle}</h4>
                <h3 class="text-xl font-bold">{step.title}</h3>
                <img src={step.src} alt="" class="max-h-[150px] mx-auto" />
                <p class="text-gray-600">{step.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Logo with subtle animation */}
        {/* <div class="relative group">
                    <img
                        src="/assets/images/dark_wrsr_universe.webp"
                        alt="Wearshare Universe"
                        class="w-[200px] h-[200px] object-contain mx-auto
                               transform transition-all duration-700
                               group-hover:scale-105 group-hover:rotate-[360deg]"
                    />
                    <div class="absolute inset-0 bg-gradient-to-t from-white via-transparent to-transparent" />
                </div> */}

        {/* Action buttons */}
        <div class="space-y-4">
          <button
            onClick={handleNextStep}
            class="w-full py-3 px-6 bg-[#BFEC09] text-black rounded-full 
                               font-bold hover:bg-[#BFEC09] active:scale-[0.98]
                               focus:outline-none focus:ring-2 focus:ring-offset-2 
                               focus:ring-black transition-all duration-200
                               flex items-center justify-center space-x-2"
          >
            <span>
              {activeStep() === steps.length - 1 ? 'Get Started' : 'Next Step'}
            </span>
            <span class="transform transition-transform duration-200 group-hover:translate-x-1">
              {activeStep() === steps.length - 1 ? '🚀' : '→'}
            </span>
          </button>

          <Show when={activeStep() < steps.length - 1}>
            <button
              onClick={handleGetStarted}
              class="w-full py-2 text-gray-500 hover:text-gray-700
                                   transition-colors duration-200"
            >
              Skip Introduction
            </button>
          </Show>
        </div>
      </div>
    </BaseModal>
  );
};
