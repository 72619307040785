import { Suspense } from 'solid-js';
import { effect } from 'solid-js/web';
import { App } from '../pages/App';
import { useLocation } from '@solidjs/router';

export default function Home() {
  const location = useLocation();
  effect(() => {
    console.log('Location changed to', location);
  });
  // return <Suspense><App /></Suspense>;
  return <App />;
}
