import { Component, createEffect, onCleanup, onMount } from 'solid-js';
import { isServer } from 'solid-js/web';
import { Rect } from '@repo/drawing';
import { Logger } from '@repo/logger';
import { DesignerRegistry, DesignerIdGenerator } from '@repo/designer';
import { WindowRef } from '@repo/designer';
// import { designerRegistry } from './DesignerRegistry';

interface BandDesignerProps {
  id: string;
  // windowRef?: WindowRef;
  onSave?: (imageData: ArrayBuffer) => Promise<void>;
  onError?: (error: Error) => void;
}

export const BandDesigner: Component<BandDesignerProps> = (props) => {
  const logger = new Logger('BandDesigner');
  // const designerRegistry = DesignerRegistry.getInstance();
  let containerRef: HTMLDivElement | undefined;
  // const designerId = DesignerIdGenerator.create(props.id); // 'band-designer');

  onMount(async () => {
    if (isServer) {
      logger.warn('Skipping designer initialization on server');
      return;
    }
    if (!containerRef) {
      logger.error('Container element not found');
      return;
    }
    try {
      // Create or get designer instance
      const designerRegistry = DesignerRegistry.getInstance();
      const designerId = DesignerIdGenerator.create(props.id); // 'band-designer');
      const designer = designerRegistry.getInstance(designerId);
      // const designer = designerRegistry.create(designerId, {
      //   bandSize: new Rect(0, 0, 1024, 7168),
      //   dev: false,
      // });
      console.log('[BandDesigner] Designer created:', designer);

      // Initialize core functionality
      await designerRegistry.initialize(designerId);

      // Bind to DOM element
      designerRegistry.bindToElement(designerId, containerRef);

      // Bind to window with configuration
      // designerRegistry.bindToWindow(designerId, props.windowRef, {
      //   enableVisibilityHandling: true
      // });
      designerRegistry.bindToWindow(designerId, window as any as WindowRef, {
        enableVisibilityHandling: true,
      });
    } catch (error) {
      logger.error('Failed to initialize designer:', error);
      props.onError?.(
        error instanceof Error
          ? error
          : new Error('Failed to initialize designer'),
      );
    }
  });

  onCleanup(() => {
    if (!isServer) {
      // Unbind but don't remove - allows component to be remounted
      // designerRegistry.unbind(designerId);
    }
  });

  return (
    <div
      ref={containerRef}
      style="width: 200px; height: 1400px"
      class="wsband_designer w-full h-full bg-gray-100"
    />
  );
};
