import { Component } from 'solid-js';

export const RotateIcon: Component = () => (
  <svg
    viewBox="0 0 24 24"
    class="w-7 h-7 transform-gpu transition-transform duration-200"
    fill="none"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
    <path d="M3 3v5h5" />
  </svg>
);

export const EyeIcon: Component = () => (
  <svg
    viewBox="0 0 24 24"
    class="w-7 h-7 transform-gpu transition-transform duration-200"
    fill="none"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    {/* Main eye shape */}
    <path d="M12 5.5C7 5.5 2.73 8.61 1 12c1.73 3.39 6 6.5 11 6.5s9.27-3.11 11-6.5c-1.73-3.39-6-6.5-11-6.5Z" />

    {/* Iris background */}
    <circle cx="12" cy="12" r="3.5" fill="white" stroke="none" />

    {/* Iris and pupil group */}
    <g class="transform-gpu transition-transform duration-300">
      <circle cx="12" cy="12" r="3" />
      <circle cx="12" cy="12" r="1.5" fill="currentColor" />
      <circle
        cx="10.5"
        cy="10.5"
        r="0.8"
        fill="white"
        stroke="none"
        class="transform-gpu transition-transform duration-300"
      />
    </g>
  </svg>
);

export const CartIcon: Component = () => (
  <svg
    viewBox="0 0 24 24"
    class="w-7 h-7 transform-gpu transition-transform duration-200"
    fill="none"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    {/* Cart body */}
    <path d="M4 7h16l-1.58 7.9a2 2 0 0 1-2 1.6H7.58a2 2 0 0 1-2-1.6L4 7z" />

    {/* Handle */}
    <path d="M9 11V6a3 3 0 0 1 6 0v5" />

    {/* Wheels */}
    <circle cx="8.5" cy="19.5" r="1.5" />
    <circle cx="15.5" cy="19.5" r="1.5" />
  </svg>
);

export const InfoIcon: Component = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M8.937 2.25a2.3 2.3 0 0 0-.9.158c-.299.122-.54.31-.762.53L2.938 7.275c-.213.214-.4.45-.525.748a2.3 2.3 0 0 0-.163.914v6.126c0 .302.036.602.158.9s.31.54.53.762l4.337 4.337c.214.213.45.4.748.524c.298.125.601.164.914.164h6.126c.302 0 .602-.035.9-.158c.299-.122.54-.31.762-.53l4.337-4.337c.213-.214.4-.45.524-.748a2.3 2.3 0 0 0 .164-.914V8.937a2.3 2.3 0 0 0-.158-.9a2.3 2.3 0 0 0-.53-.762l-4.337-4.337a2.3 2.3 0 0 0-.762-.53a2.3 2.3 0 0 0-.9-.158zm3.063 5a.75.75 0 0 1 .75.75v.5a.75.75 0 0 1-1.5 0V8a.75.75 0 0 1 .75-.75M10.75 16a.75.75 0 0 1 .5-.707v-3.586a.75.75 0 0 1 .25-1.457h.5a.75.75 0 0 1 .75.75v4.293a.75.75 0 0 1-.25 1.457h-1a.75.75 0 0 1-.75-.75"
    />
  </svg>
);

// Optional: Add a new CartWithPlus variant that shows a "+" symbol
export const CartWithPlusIcon: Component = () => (
  <svg
    viewBox="0 0 24 24"
    class="w-7 h-7 transform-gpu transition-transform duration-200"
    fill="none"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    {/* Cart body */}
    <path d="M4 7h16l-1.58 7.9a2 2 0 0 1-2 1.6H7.58a2 2 0 0 1-2-1.6L4 7z" />

    {/* Handle */}
    <path d="M9 11V6a3 3 0 0 1 6 0v5" />

    {/* Wheels */}
    <circle cx="8.5" cy="19.5" r="1.5" />
    <circle cx="15.5" cy="19.5" r="1.5" />

    {/* Plus symbol */}
    <path d="M15 4h6M18 1v6" class="stroke-2" />
  </svg>
);

// Icon wrapper with hover effect
export interface IconWrapperProps {
  children: any;
  class?: string;
}

export const IconWrapper: Component<IconWrapperProps> = (props) => (
  <div
    class={`
      relative inline-flex items-center justify-center
      transform-gpu transition-transform duration-200
      hover:scale-110 active:scale-95
      ${props.class || ''}
    `}
  >
    {props.children}
  </div>
);
