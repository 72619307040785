import { Component, createSignal, createEffect, Show } from 'solid-js';
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableHeader,
} from '~/components/ui/table';
import {
  Pagination,
  PaginationEllipsis,
  PaginationItem,
  PaginationItems,
  PaginationNext,
  PaginationPrevious,
} from '~/components/ui/pagination';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '~/components/ui/dialog';
import { ImageLoader } from '../../../../components/images/ImageLoader';
import { formatBytes, formatDate } from '@repo/shared';

interface ImageDimensions {
  width: number;
  height: number;
}

interface ImageCacheEntry {
  key: string;
  type: string;
  size: number;
  timestamp: number;
  expiresAt: number;
  fileExt?: {
    width: number;
    height: number;
  };
}

interface FileCacheTableProps {
  data: ImageCacheEntry[];
  pageSize?: number;
  onClearItem: (key: string) => Promise<void>;
}

export const FileCacheTable: Component<FileCacheTableProps> = (props) => {
  const [currentPage, setCurrentPage] = createSignal(1);
  const [selectedImage, setSelectedImage] =
    createSignal<ImageCacheEntry | null>(null);
  const [imageDimensions, setImageDimensions] = createSignal<
    Record<string, ImageDimensions>
  >({});

  const pageSize = () => props.pageSize || 5;
  const totalPages = () => Math.ceil(props.data.length / pageSize());
  const startIndex = () => (currentPage() - 1) * pageSize();
  const endIndex = () => Math.min(startIndex() + pageSize(), props.data.length);
  const currentData = () => props.data.slice(startIndex(), endIndex());

  // Initialize dimensions from fileExt if available
  createEffect(() => {
    const newDimensions: Record<string, ImageDimensions> = {};
    props.data.forEach((entry) => {
      if (entry.fileExt) {
        newDimensions[entry.key] = {
          width: entry.fileExt.width,
          height: entry.fileExt.height,
        };
      }
    });
    setImageDimensions((prev) => ({ ...prev, ...newDimensions }));
  });

  // Custom item component that matches the expected type
  const PaginationItemComponent: Component<{ page: number }> = (props) => (
    <PaginationItem
      page={props.page}
      onClick={(e: Event) => {
        e.preventDefault();
        setCurrentPage(props.page);
      }}
    >
      {props.page}
    </PaginationItem>
  );

  // Custom ellipsis component with correct return type
  const EllipsisComponent = () => <PaginationEllipsis />;

  // Handle pagination change events
  const handlePaginationChange = (e: Event) => {
    const target = e.target as HTMLElement;
    const page = Number(target.getAttribute('data-page'));
    if (!isNaN(page)) {
      setCurrentPage(page);
    }
  };

  return (
    <div class="space-y-4">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Key (Hash)</TableHead>
            <TableHead>Type</TableHead>
            <TableHead>Size</TableHead>
            <TableHead>Created</TableHead>
            <TableHead>Expires</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {props.data.map((entry) => (
            <TableRow>
              <TableCell class="font-mono text-xs">
                {entry.key.substring(0, 8)}...
              </TableCell>
              <TableCell>{entry.type}</TableCell>
              <TableCell>{formatBytes(entry.size)}</TableCell>
              <TableCell>{formatDate(entry.timestamp)}</TableCell>
              <TableCell>{formatDate(entry.expiresAt)}</TableCell>
              <TableCell>
                <Button
                  variant="destructive"
                  size="sm"
                  onClick={() => props.onClearItem(entry.key)}
                >
                  Clear
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Pagination
        count={totalPages()}
        page={currentPage()}
        onChange={handlePaginationChange}
        itemComponent={PaginationItemComponent}
        ellipsisComponent={EllipsisComponent}
      >
        <PaginationPrevious
          onClick={(e: Event) => {
            e.preventDefault();
            if (currentPage() > 1) {
              setCurrentPage(currentPage() - 1);
            }
          }}
          disabled={currentPage() === 1}
        />
        <PaginationItems />
        <PaginationNext
          onClick={(e: Event) => {
            e.preventDefault();
            if (currentPage() < totalPages()) {
              setCurrentPage(currentPage() + 1);
            }
          }}
          disabled={currentPage() === totalPages()}
        />
      </Pagination>

      <Dialog
        open={!!selectedImage()}
        onOpenChange={() => setSelectedImage(null)}
      >
        <DialogContent class="max-w-4xl z-50">
          <DialogHeader>
            <DialogTitle>Image Preview - {selectedImage()?.key}</DialogTitle>
          </DialogHeader>
          <Show when={selectedImage()}>
            <ImageLoader
              imageKey={selectedImage()!.key}
              alt={selectedImage()!.key}
              size="2xl"
            />
          </Show>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FileCacheTable;
