import { Component, createSignal, Show } from 'solid-js';
import { RotateIcon, EyeIcon, CartIcon, InfoIcon } from './Icons';
import styles from './ActionButton.module.scss';
import { ActionType, ActionConfig, Managers } from './ActionButtonTypes';

interface ActionButtonProps {
  type: ActionType;
  config: ActionConfig;
  isInitialized: boolean;
  managers: Managers | null;
  isDesktop: boolean;
  isSelected?: boolean;
  onAction: () => void;
  onInteraction: (e: MouseEvent | TouchEvent, isHoverStart?: boolean) => void;
}

const iconComponents = {
  /* rotate: RotateIcon, */
  welcome: InfoIcon,
  eye: EyeIcon,
  cart: CartIcon,
} as const;

export const ActionButton: Component<ActionButtonProps> = (props) => {
  let buttonRef: HTMLButtonElement | undefined;
  const [isTooltipVisible, setTooltipVisible] = createSignal(false);
  const [isTouchActive, setTouchActive] = createSignal(false);
  const [touchStartTime, setTouchStartTime] = createSignal(0);
  const [touchStartPos, setTouchStartPos] = createSignal({ x: 0, y: 0 });
  let lastInteractionTime = 0;

  const IconComponent = iconComponents[props.config.iconType];

  const getButtonCenter = () => {
    if (!buttonRef) return { x: 0, y: 0 };
    const rect = buttonRef.getBoundingClientRect();
    return {
      x: rect.left + rect.width / 2,
      y: rect.top + rect.height / 2,
    };
  };

  const triggerInteraction = (
    e: MouseEvent | TouchEvent,
    isHoverStart: boolean,
  ) => {
    if (!props.isInitialized) return;
    const now = Date.now();
    if (now - lastInteractionTime > 100) {
      lastInteractionTime = now;
      const center = getButtonCenter();
      props.onInteraction(
        { ...e, clientX: center.x, clientY: center.y },
        isHoverStart,
      );
    }
  };

  const handleTouchStart = (e: TouchEvent) => {
    if (!props.isInitialized) return;
    e.preventDefault(); // Prevent mouse events from firing

    setTouchActive(true);
    setTouchStartTime(Date.now());

    const touch = e.touches[0];
    if (touch === undefined) return;
    setTouchStartPos({ x: touch.clientX, y: touch.clientY });

    triggerInteraction(e, true);

    // Request haptic feedback if available
    if (props.managers?.device) {
      props.managers.device.requestHapticFeedback([5]);
    }
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (!props.isInitialized || !isTouchActive()) return;

    const touch = e.touches[0];
    if (touch === undefined) return;
    const startPos = touchStartPos();

    // Calculate distance moved
    const deltaX = touch.clientX - startPos.x;
    const deltaY = touch.clientY - startPos.y;
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    // If moved more than 10px, cancel the touch
    if (distance > 10) {
      setTouchActive(false);
    }
  };

  const handleTouchEnd = (e: TouchEvent) => {
    if (!props.isInitialized) return;
    e.preventDefault(); // Prevent mouse events from firing

    const touchDuration = Date.now() - touchStartTime();

    if (isTouchActive() && touchDuration < 500) {
      // Request haptic feedback for successful click
      if (props.managers?.device) {
        props.managers.device.requestHapticFeedback([10, 20]);
      }

      // Trigger the action
      props.onAction();
    }

    setTouchActive(false);
    triggerInteraction(e, false);
  };

  const handleTouchCancel = (e: TouchEvent) => {
    if (!props.isInitialized) return;
    setTouchActive(false);
    triggerInteraction(e, false);
  };

  const handleMouseEnter = (e: MouseEvent) => {
    if (!props.isInitialized || !props.isDesktop) return;
    props.managers?.sound?.play('hover');
    setTooltipVisible(true);
    triggerInteraction(e, true);
  };

  const handleMouseLeave = (e: MouseEvent) => {
    if (!props.isInitialized || !props.isDesktop) return;
    setTooltipVisible(false);
    triggerInteraction(e, false);
  };

  const preventDefault = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <button
      ref={buttonRef}
      class={`
        relative flex items-center justify-center
        w-20 h-20 rounded-full border-2 border-white/10 dark:border-black/10
        transform-gpu transition-all duration-300 ease-in-out
        shadow-lg dark:shadow-xl shadow-black/5 dark:shadow-black/20
        ${props.config.colorClasses.base}
        ${props.isInitialized ? props.config.colorClasses.hover : ''}
        ${props.isInitialized ? props.config.colorClasses.active : ''}
        ${props.isSelected ? props.config.colorClasses.selected : ''}
        ${styles.actionButton}
        ${isTouchActive() ? styles.touchActive : ''}
        ${!props.isInitialized ? 'pointer-events-none opacity-50' : ''}
      `}
      onClick={() => props.isDesktop && props.isInitialized && props.onAction()}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchCancel}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onDragStart={preventDefault}
      onContextMenu={preventDefault}
      draggable={false}
      aria-label={props.config.label}
      disabled={!props.isInitialized}
    >
      <div class={styles.buttonContent}>
        <IconComponent />
      </div>

      <Show when={props.isDesktop}>
        <div
          class={`${styles.tooltip} ${isTooltipVisible() ? styles.tooltipVisible : ''}`}
        >
          {props.config.label}
        </div>
      </Show>
    </button>
  );
};
