import { Component, Show, onMount, Suspense } from 'solid-js';
import { ActionButtonsContainer } from '../components/ActionButtons/ActionButtonsContainer';
import { BandDesigner } from '../components/BandDesigner';
import { DevActionsTab } from '../features/dev/DevActionsTab';
import { ModalManager } from '../features/modals/ModalManager';
import {
  Designer,
  DesignerRegistry,
  DesignerIdGenerator,
} from '@repo/designer';
import { ImageBufferUtils } from '@repo/shared';
import { ModalManagerStore } from '~/features/modals/ModalManager.store';
import { Logger } from '@repo/logger';

import {
  ensureSession,
  // checkSession,
  // createBandFromImageData,
  // createBandFromImageId,
} from '../services/server.js';

import { Rect } from '@repo/drawing';
import { preview } from 'vite';
// import { Confetti } from '@repo/ui/Confetti';
import { createSession } from '../services/server';

export const App: Component = () => {
  const logger = new Logger('App');

  // Initialize store with designer instance
  onMount(async () => {
    const designerId = DesignerIdGenerator.create('main-designer');
    const designerRegistry = DesignerRegistry.getInstance();
    const designer = designerRegistry.create(designerId, {
      bandSize: new Rect(0, 0, 1024, 7168),
      dev: false,
      // bandId: '73a23aea-1f2b-4608-8836-a470912332a5',
      // bandId: 'ecc065f8-37ca-4375-a57d-5d8ae1a6c47d',
    });
    console.log('[App.tsx] Designer created:', designer);
  });

  // let layout: SerializedLayout | undefined = undefined;

  const onPurchaseClicked2 = async () => {
    console.log('Purchase clicked');
    const designerRegistry = DesignerRegistry.getInstance();
    const designerId = DesignerIdGenerator.create('main-designer');
    const designer = designerRegistry.getInstance(designerId)!;
    logger.info('Calling save on controller:', {
      band: designer.controller.state.band,
      layout: designer.controller.state.bandLayout,
    });
    const band = await designer.controller.saveCurrentBand();
    // console.log('Band saved:', band);
    logger.info('Band saved:', { band });
    bandId = band?.id;
    // layout = await designer.controller.getSerializedLayout();
    // console.log('Serialized layout:', layout);
  };

  // const OG_01 =
  //   '{"type":"og7","data":"[null,null,"3af5c9b4-a18c-4b41-a45f-2e9b1b43e70e",null,null,null,null]"}';
  // const BANNER_01 =
  //   '{"type":"banner","data":"3af5c9b4-a18c-4b41-a45f-2e9b1b43e70e"}';

  // const OG_01: SerializedLayout = {
  //   type: 'og7',
  //   data: '[null,"3af5c9b4-a18c-4b41-a45f-2e9b1b43e70e",null,null,null,null,null]',
  // };

  // const OG_02: SerializedLayout = {
  //   type: 'og7',
  //   data: '[null,"5bf362e5-52a3-4205-9c57-5b6d4563ed99",null,null,null,null,null]',
  // };

  // const BANNER_01: SerializedLayout = {
  //   type: 'banner',
  //   data: '3af5c9b4-a18c-4b41-a45f-2e9b1b43e70e',
  // };

  // const BANNER_02 = '{"type":"banner","data":"7a7d464d-4bcb-4667-915a-f2456954a3a5"}';

  // layout = OG_02;

  let bandId: string | undefined = undefined;

  const onPreviewClicked2 = async () => {
    if (!bandId) {
      console.error('No layout to preview');
      return;
    }
    // const bannerJSON =
    //   '{"type":"banner","data":"7a7d464d-4bcb-4667-915a-f2456954a3a5"}';
    // const bannerData = JSON.parse(layout);
    // const bannerData = layout;
    const designerRegistry = DesignerRegistry.getInstance();
    const designerId = DesignerIdGenerator.create('main-designer');
    const designer = designerRegistry.getInstance(designerId)!;
    // await designer.controller.restoreLayout(bannerData);
    await designer.controller.loadBand(bandId);
  };

  const onWelcomeClicked = async () => {
    console.log('Welcome clicked');
    const store = ModalManagerStore.getInstance();
    logger.info('Opening welcome modal');
    const designerRegistry = DesignerRegistry.getInstance();
    const designerId = DesignerIdGenerator.create('main-designer');
    const designer = designerRegistry.getInstance(designerId)!;
    const state = designer.controller.state;
    state.previewMode = true;
    designer.controller.state = state;
    designer.controller.render();
    await new Promise((resolve) => setTimeout(resolve, 0));

    store.openModal('MODAL_WELCOME', {step: 0}, () => {
      logger.info('Closing welcome modal');
      state.previewMode = false;
      designer.controller.state = state;
      designer.controller.render();
    });
  }

  const onPurchaseClicked = async () => {
    console.log('Purchase clicked');

    const store = ModalManagerStore.getInstance();
    logger.info('Opening create modal');
    const designerRegistry = DesignerRegistry.getInstance();
    const designerId = DesignerIdGenerator.create('main-designer');
    const designer = designerRegistry.getInstance(designerId)!;
    const state = designer.controller.state;
    state.previewMode = true;
    designer.controller.state = state;
    designer.controller.render();
    await new Promise((resolve) => setTimeout(resolve, 0));

    store.openModal('MODAL_CREATE', undefined, () => {
      logger.info('Closing create modal');
      state.previewMode = false;
      designer.controller.state = state;
      designer.controller.render();
    });
  };

  const onPreviewClicked = async () => {
    const store = ModalManagerStore.getInstance();
    // 78086e68-642f-4c26-90bb-3689eef1f36d
    // TODO(pbirch): Modal is not respecting the designId prop, it's always showing the current design
    // store.openModal('MODAL_PREVIEW', { designId: '09e7a610-4f1a-4e3f-8bac-4f11db8aa95b' });
    logger.info('Opening preview modal');
    const designerRegistry = DesignerRegistry.getInstance();
    const designerId = DesignerIdGenerator.create('main-designer');
    const designer = designerRegistry.getInstance(designerId)!;
    const state = designer.controller.state;
    state.previewMode = true;
    designer.controller.state = state;
    designer.controller.render();
    await new Promise((resolve) => setTimeout(resolve, 10));

    store.openModal(
      'MODAL_PREVIEW',
      {
        // designId: '78086e68-642f-4c26-90bb-3689eef1f36d',
      },
      () => {
        logger.info('Closing preview modal');
        state.previewMode = false;
        designer.controller.state = state;
        designer.controller.render();
      },
    );
  };

  const session = ensureSession();
  logger.info('Ensure Session:', { session });

  return (
    <>
      {/* <Header /> */}
      {/* <Confetti active={true} /> */}
      {/* <UserPreferencesTab /> */}
      <ModalManager />
      {/* <DevActionsTab /> */}

      <ActionButtonsContainer
        /* onRotate={() => console.log('Rotate clicked')} */
        onWelcome={onWelcomeClicked}
        onPurchase={onPurchaseClicked}
        onPreview={onPreviewClicked}
      />

      {/* <DesignerWrapper /> */}
      <div class="wrapper">
        {/* <BandDesigner id='main-designer' windowRef={window as any as WindowRef} /> */}
        <BandDesigner id="main-designer" />
      </div>
    </>
  );
};

// import { Header } from '../components/Header/Header.jsx';
// import { WindowRef } from '@repo/designer';
// import { UserPreferencesTab } from '../features/preferences/UserPreferencesTab';
// import { SerializedLayout } from '../../../../packages/designer/src/Layouts';
import { DesignerStateSchema } from '../../../../packages/designer/docs/DesignerStateSchema';

// const designerRegistry = DesignerRegistry.getInstance();
// const designerId = DesignerIdGenerator.create('main-designer');
// const designer: Designer = designerRegistry.getInstance(designerId)!;
// const canvas = designer.container.buffer!;
// const data = await ImageBufferUtils.canvasToJpgArrayBuffer(canvas);
// const result = await createBandFromImageData({
//   id: 'asdf',
//   title: 'asdf',
//   description: 'asdf',
//   data
// });

// const result = await createBandFromImageId({
//   id: 'asdf',
//   title: 'asdf',
//   description: 'asdf',
//   imageName: '78086e68-642f-4c26-90bb-3689eef1f36d'
// });

// console.log('Upload result:', result);

// appStore.openModal('MODAL_CREATE');
// appStore.openModal('MODAL_PURCHASE', { designId: '09e7a610-4f1a-4e3f-8bac-4f11db8aa95b' });
// appStore.openModal('MODAL_PREVIEW', { designId: '09e7a610-4f1a-4e3f-8bac-4f11db8aa95b' });
// appStore.openModal('MODAL_WELCOME');
// appStore.openModal('MODAL_WORKING');

// Error handling:
// const [error, setError] = createSignal<string | null>(null);

// // Show error tooltip when action fails
// {error() && (
//     <div class="error-tooltip">{error()}</div>
// )}

// Progress Indicator:
// const [loading, setLoading] = createSignal(false);
// // Add to buttons when actions are async
// <div class={`loading-spinner ${loading() ? 'opacity-100' : 'opacity-0'}`} />
