import { Component, onCleanup, onMount, Show } from 'solid-js';
import * as drawing from '@repo/drawing';

interface ConfettiPiece {
  color: string;
  x: number;
  y: number;
  r: number;
  d: number;
  q: number;
}

export const Confetti: Component<{ active: boolean }> = (props) => {
  let canvasRef: HTMLCanvasElement | undefined;
  let confetti: ConfettiPiece[] = [];
  let animationFrame: number;

  const colors = ['#fbff53', '#ff53a0', '#53ff7c', '#53b3ff'];

  const createConfetti = () => {
    const pieces = [];
    for (let i = 0; i < 100; i++) {
      pieces.push({
        color: colors[Math.floor(Math.random() * colors.length)],
        x: Math.random() * window.innerWidth,
        y: Math.random() * -100,
        r: Math.random() * 4 + 2, // radius
        d: Math.random() * 6 + 4, // density
        q: Math.random() * Math.PI * 2, // angle
      });
    }
    return pieces;
  };

  const animate = () => {
    if (!canvasRef) return;
    const ctx = canvasRef.getContext('2d')!;

    ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);

    confetti.forEach((piece, i) => {
      piece.y += piece.d;
      piece.q += 0.1;

      ctx.beginPath();
      ctx.moveTo(piece.x, piece.y);
      ctx.fillStyle = piece.color;
      ctx.arc(
        piece.x + Math.sin(piece.q) * 20,
        piece.y,
        piece.r,
        0,
        Math.PI * 2,
        false,
      );
      ctx.fill();

      // Remove confetti when it goes off screen
      if (piece.y > window.innerHeight) {
        confetti[i] = {
          ...createConfetti()[0],
          y: Math.random() * -100,
        };
      }
    });

    if (props.active) {
      animationFrame = requestAnimationFrame(animate);
    }
  };

  const start = () => {
    if (!canvasRef) return;
    canvasRef.width = window.innerWidth;
    canvasRef.height = window.innerHeight;
    confetti = createConfetti();
    animate();
  };

  const stop = () => {
    if (animationFrame) {
      cancelAnimationFrame(animationFrame);
    }
  };

  onMount(() => {
    // Handle window resizing
    const handleResize = () => {
      if (canvasRef) {
        canvasRef.width = window.innerWidth;
        canvasRef.height = window.innerHeight;
      }
    };
    window.addEventListener('resize', handleResize);
    onCleanup(() => window.removeEventListener('resize', handleResize));
  });

  onCleanup(stop);

  return (
    <Show when={props.active}>
      <canvas
        ref={(el) => {
          canvasRef = el;
          start();
        }}
        class="fixed inset-0 pointer-events-none z-50"
        style={{ 'touch-action': 'none' }}
      />
    </Show>
  );
};
