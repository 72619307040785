import {
  Component,
  Show,
  createEffect,
  mergeProps,
  createSignal,
} from 'solid-js';
import {
  Designer,
  DesignerRegistry,
  DesignerIdGenerator,
} from '@repo/designer';
import { BaseModal } from './BaseModal.jsx';
import { Analytics } from '../../utils/Analytics.js';
import { Logger } from '@repo/logger';
import { PerformanceMonitor } from '../../utils/PerformanceMonitor.js';
import { ModalManagerStore } from '~/features/modals/ModalManager.store';

export const BASE_IMAGE_URL = 'https://images.wrsr.io/v1';
export type DesignId = string;

export type OnCloseHandler = () => boolean | undefined | void;

interface PurchaseModalProps {
  onClose: OnCloseHandler;
  onContinue: () => void;
  onStartNew: () => void;
  designId: DesignId;
  previewUrl?: URL;
}

export const PurchaseModal: Component<PurchaseModalProps> = (props) => {
  const analytics = Analytics.getInstance();
  const performance = PerformanceMonitor.getInstance();
  const logger = Logger.getInstance();

  const [isLoading, setIsLoading] = createSignal(false);
  const [showConfetti, setShowConfetti] = createSignal(false);
  const [storeProps, setStoreProps] = createSignal({});
  const mergedProps = mergeProps(props, storeProps);
  const [activeTab, setActiveTab] = createSignal<'preview' | 'details'>(
    'preview',
  );
  const [hoverEffect, setHoverEffect] = createSignal<string | null>(null);

  const store = ModalManagerStore.getInstance();

  createEffect(() => {
    const activeModal = store.getActiveModal();
    if (activeModal?.props) {
      console.log(activeModal.props);
      setStoreProps(activeModal.props);
    }
  });

  const previewUrl = new URL(`${BASE_IMAGE_URL}/${props.designId}`);

  const handlePurchase = async () => {
    setIsLoading(true);
    const measure = performance.markAndMeasure(
      'purchase-click',
      'purchase-redirect',
    );

    const designId = props.designId ? props.designId : mergedProps.designId;

    console.log(mergedProps.previewUrl);

    try {
      analytics.trackEvent({
        category: 'Purchase',
        action: 'Initiated',
        metadata: {
          designId,
          previewUrl: mergedProps.previewUrl
            ? mergedProps.previewUrl
            : props.previewUrl,
        },
      });

      // TODO(pbirch): Open the purchase URL in a new tab
      window.open(`https://shop.wearshare.com/products/${designId}`, '_blank');
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setShowConfetti(true);

      // Reset confetti after animation
      setTimeout(() => setShowConfetti(false), 3000);

      logger.info('[PurchaseModal] Purchase initiated', {
        designId: props.designId,
        previewUrl: props.previewUrl?.toString(),
      });
    } catch (error) {
      const err = error instanceof Error ? error : new Error('Unknown error');
      logger.error('[PurchaseModal] Failed to initiate purchase', err, {
        designId: props.designId,
        previewUrl: props.previewUrl?.toString(),
      });
    } finally {
      setIsLoading(false);
      measure.end();
    }
  };

  const handleKeepWorking = async () => {
    const store = ModalManagerStore.getInstance();
    store.closeModal();

    // props.onClose();
  };

  const handleStartNew = async () => {
    // Get designer instance
    const registry = DesignerRegistry.getInstance();
    const designerId = DesignerIdGenerator.create('main-designer');
    const designer = registry.getInstance(designerId); // ?? props.designer;

    if (!designer) {
      logger.error('No designer instance found');
      setIsLoading(false);
      return;
    }
    await designer.reset();

    const store = ModalManagerStore.getInstance();
    store.closeModal();
    // props.onClose();
  };

  return (
    <BaseModal
      key="MODAL_PURCHASE"
      title="Your Band is Saved!"
      onClose={props.onClose}
      description="We’ve sent you a link via text and/or email. "
      size="medium"
    >
      <div class="space-y-8 py-4">
        {/* Success Message with Animation */}
        <div class="text-center">
          <div class="bg-green-50 rounded-lg p-4 mb-6 transform transition-all duration-300 hover:scale-[1.02]">
            <div class="flex items-center justify-center space-x-2">
              <div class="relative">
                <svg
                  class="h-6 w-6 text-green-500 animate-bounce"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                {showConfetti() && (
                  <div class="absolute -inset-4">
                    {/* Add confetti animation elements */}
                    <div class="confetti-piece" />
                    <div class="confetti-piece" />
                    <div class="confetti-piece" />
                  </div>
                )}
              </div>
              <span class="text-green-700 font-medium">
                Design Saved Successfully
              </span>
            </div>
          </div>
        </div>

        {/* Preview Image */}
        <div class="relative aspect-video w-full mx-auto overflow-hidden rounded-lg">
          <img
            src={
              mergedProps.previewUrl?.toString()
                ? mergedProps.previewUrl?.toString()
                : previewUrl.href
            }
            alt="Your band design preview"
            class="w-full h-full object-contain transition-all duration-300 hover:scale-105"
            loading="lazy"
          />
          <div class="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent pointer-events-none" />
        </div>

        <p class="text-center font-medium">
          Do you want to purchase it now or keep designing?
        </p>

        {/* Action Buttons */}
        <div class="space-y-4">
          <button
            onClick={handlePurchase}
            disabled={isLoading()}
            class="group w-full bg-[#BFEC09] text-black py-3 px-6 rounded-full 
                               font-bold relative overflow-hidden transform transition-all duration-200
                               hover:bg-[#BFEC09] focus:outline-none focus:ring-2 
                               focus:ring-offset-2 focus:ring-black disabled:opacity-50"
            onMouseEnter={() => setHoverEffect('purchase')}
            onMouseLeave={() => setHoverEffect(null)}
          >
            <span class="relative z-10 flex items-center justify-center space-x-2">
              {isLoading() ? (
                <>
                  <div class="animate-spin h-5 w-5 border-2 border-current border-t-transparent rounded-full" />
                  <span>Processing...</span>
                </>
              ) : (
                <>
                  <svg
                    class="w-5 h-5 transform transition-transform duration-200 group-hover:rotate-12"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                    />
                  </svg>
                  <span>Purchase Now</span>
                </>
              )}
            </span>
            <div
              class={`absolute inset-0 transform transition-transform duration-500 ${
                hoverEffect() === 'purchase' ? 'scale-x-100' : 'scale-x-0'
              } origin-left bg-yellow-300/20`}
            />
          </button>

          <button
            onClick={handleKeepWorking}
            class="group w-full bg-white text-black py-3 px-6 rounded-full 
                               font-bold border-2 border-black relative overflow-hidden
                               transform transition-all duration-200 hover:bg-gray-50"
            onMouseEnter={() => setHoverEffect('new')}
            onMouseLeave={() => setHoverEffect(null)}
          >
            <span class="relative z-10 flex items-center justify-center space-x-2">
              <svg
                class="w-5 h-5 transform transition-transform duration-200 group-hover:rotate-180"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              <span>Keep Working</span>
            </span>
            <div
              class={`absolute inset-0 transform transition-transform duration-500 ${
                hoverEffect() === 'new' ? 'scale-x-100' : 'scale-x-0'
              } origin-left bg-gray-100`}
            />
          </button>

          <button
            onClick={handleStartNew}
            class="group w-full bg-white text-black py-3 px-6 rounded-full 
                               font-bold border-2 border-black relative overflow-hidden
                               transform transition-all duration-200 hover:bg-gray-50"
            onMouseEnter={() => setHoverEffect('new')}
            onMouseLeave={() => setHoverEffect(null)}
          >
            <span class="relative z-10 flex items-center justify-center space-x-2">
              <svg
                class="w-5 h-5 transform transition-transform duration-200 group-hover:rotate-180"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              <span>Start New Design</span>
            </span>
            <div
              class={`absolute inset-0 transform transition-transform duration-500 ${
                hoverEffect() === 'new' ? 'scale-x-100' : 'scale-x-0'
              } origin-left bg-gray-100`}
            />
          </button>
        </div>

        {/* Next Steps Section */}
        <div class="border-t border-gray-200 pt-4">
          <div class="rounded-lg bg-gray-50 p-4 transform transition-all duration-300 hover:scale-[1.01]">
            <h4 class="text-sm font-medium text-gray-900 mb-2">
              What happens next?
            </h4>
            <ul class="text-sm text-gray-600 space-y-2">
              {[
                "Click 'Purchase Now' to complete your order",
                'Your band will be manufactured with your custom design',
                'Shipping updates will be sent to your provided contact info',
              ].map((text, index) => (
                <li class="flex items-start group">
                  <svg
                    class="w-4 h-4 text-green-500 mt-0.5 mr-2 transform transition-transform duration-200 group-hover:scale-110"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  {text}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};
