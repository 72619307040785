import { Component, Show } from 'solid-js';
import { JSX } from 'solid-js/jsx-runtime';
import { Portal } from 'solid-js/web';
import { Transition } from 'solid-transition-group';
import { Logger } from '@repo/logger';
import { ModalId } from '../types';
import { ModalManagerStore } from './ModalManager.store';
import styles from './BaseModal.module.scss';
import { cn } from '~/lib/utils';

interface BaseModalProps {
  key: ModalId;
  title: string;
  onClose?: () => boolean | undefined | void;
  description?: string;
  size?: 'small' | 'medium' | 'large' | 'full';
  isSystemModal?: boolean;
  children: JSX.Element;
  bgColor?: string;
}

export const BaseModal: Component<BaseModalProps> = (props) => {
  const logger = new Logger('BaseModal');
  const store = ModalManagerStore.getInstance();

  const handleModalClose = () => {
    const cancelClose = props.onClose?.();
    if (cancelClose) return;
    if (!props.isSystemModal) {
      store.closeModal();
    }
  };

  const handleBackdropClick = (e: MouseEvent) => {
    if (e.target === e.currentTarget) {
      handleModalClose();
    }
  };

  const handleEscapeKey = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && !props.isSystemModal) {
      handleModalClose();
    }
  };

  const handleBeforeEnter = (el: Element) => {
    (el as HTMLElement).style.display = 'block';
    window.addEventListener('keydown', handleEscapeKey);
  };

  const handleAfterExit = (el: Element) => {
    (el as HTMLElement).style.display = 'none';
    window.removeEventListener('keydown', handleEscapeKey);
  };

  return (
    <Show when={store.isModalOpen(props.key)}>
      <Portal>
        <Transition
          appear={true}
          onBeforeEnter={handleBeforeEnter}
          onAfterExit={handleAfterExit}
        >
          <div
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
            aria-describedby={
              props.description ? 'modal-description' : undefined
            }
            class="fixed inset-0 z-[50] overflow-y-auto"
          >
            {/* Backdrop */}
            <div
              class={styles.backdrop}
              aria-hidden="true"
              onClick={handleBackdropClick}
            />

            {/* Modal Positioner */}
            <div class="min-h-screen px-4 flex items-center justify-center">
              {/* Modal Content */}
              <div
                class={cn(
                  styles.container,
                  styles[`size-${props.size || 'small'}`],
                  styles[`bg-[${props.bgColor || '#ffffff'}]`],
                  'px-4 pb-8 pt-4 sm:p-6',
                  'w-full mx-auto', // Add explicit width control
                )}
              >
                {/* Close Button */}
                <Show when={!props.isSystemModal}>
                  <button
                    onClick={handleModalClose}
                    class="absolute right-2 top-2 p-2 rounded-lg
                           hover:bg-muted not-dark:hover:bg-muted/20
                           active:scale-95 focus:outline-none focus:ring-2
                           transition-all duration-200
                           hover:rotate-90"
                    aria-label="Close modal"
                  >
                    <img
                      src="/assets/images/cancel_28dp_000_FILL0_wght400_GRAD0_opsz24.png"
                      alt=""
                      class="w-5 h-5 transition-transform not-dark:invert"
                    />
                  </button>
                </Show>

                {/* Header */}
                <div class="text-center mb-4">
                  <h3
                    id="modal-title"
                    class="text-xl font-semibold text-foreground
                           not-dark:text-foreground transition-colors"
                  >
                    {props.title}
                  </h3>

                  <Show when={props.description}>
                    <p
                      id="modal-description"
                      class="mt-2 text-sm text-muted-foreground
                             not-dark:text-muted-foreground transition-colors"
                    >
                      {props.description}
                    </p>
                  </Show>
                </div>

                {/* Content */}
                <div class="relative">{props.children}</div>
              </div>
            </div>
          </div>
        </Transition>
      </Portal>
    </Show>
  );
};

// import { Component, Show } from 'solid-js';
// import { JSX } from 'solid-js/jsx-runtime';
// import { Portal } from 'solid-js/web';
// import { Transition } from 'solid-transition-group';
// import { Logger } from '@repo/logger';
// import { ModalId } from '../types.js';
// import { ModalManagerStore } from './ModalManager.store.js';

// interface BaseModalProps {
//   key: ModalId;
//   title: string;
//   onClose?: () => boolean | undefined | void;
//   description?: string;
//   fullWidth?: string;
//   bgColor?: string;
//   isSystemModal?: boolean;
//   children: JSX.Element;
// }

// // export const modalStyles = {
// //   input: `font-roboto form-input w-full px-4 py-3 border-black border-[3px]
// //             rounded-lg placeholder-black text-black text-center font-semibold
// //             transition-all duration-200 focus:ring-2 focus:ring-yellow-400
// //             focus:border-yellow-500 hover:border-yellow-400`,

// //   button: {
// //     primary: `w-full py-3 px-6 bg-[#fbff53] text-black rounded-full
// //                   font-bold hover:bg-[#fcff80] active:scale-[0.98]
// //                   focus:outline-none focus:ring-2 focus:ring-offset-2
// //                   focus:ring-black transition-all duration-200`,

// //     secondary: `w-full py-3 px-6 bg-white text-black rounded-full
// //                     font-bold border-2 border-black hover:bg-gray-50
// //                     active:scale-[0.98] focus:outline-none focus:ring-2
// //                     focus:ring-offset-2 focus:ring-black transition-all duration-200`,
// //   },

// //   container: `relative transform overflow-hidden rounded-[30px]
// //                 border-black border-[3px] bg-white p-6 text-left
// //                 shadow-2xl will-change-transform transition-all duration-700`,

// //   heading: `text-2xl font-bold mb-4 text-center transition-colors
// //              duration-200 hover:text-gray-800`,

// //   description: `text-gray-600 text-center mb-6`,
// // };

// export const modalStyles = {
//   input: `font-roboto form-input w-full px-4 py-3 border-black border-[3px]
//           rounded-lg placeholder-black text-black text-center font-semibold
//           transition-all duration-200 focus:ring-2 focus:ring-yellow-400
//           focus:border-yellow-500 hover:border-yellow-400`,

//   button: {
//     primary: `w-full py-3 px-6 bg-[#fbff53] text-black rounded-full
//               font-bold hover:bg-[#fcff80] active:scale-[0.98]
//               focus:outline-none focus:ring-2 focus:ring-offset-2
//               focus:ring-black transition-all duration-200`,

//     secondary: `w-full py-3 px-6 bg-white text-black rounded-full
//                 font-bold border-2 border-black hover:bg-gray-50
//                 active:scale-[0.98] focus:outline-none focus:ring-2
//                 focus:ring-offset-2 focus:ring-black transition-all duration-200`,
//   },

//   container: `relative transform overflow-hidden rounded-[30px]
//               border-black border-[3px] text-left
//               shadow-2xl will-change-transform transition-all duration-700`,
// };

// export const BaseModal: Component<BaseModalProps> = (props) => {
//   const logger = new Logger('BaseModal');
//   const store = ModalManagerStore.getInstance();

//   const handleModalClose = () => {
//     const cancelClose = props.onClose?.();
//     logger.debug(
//       `[BaseModal] handleClose { cancelled: ${cancelClose}, isSystemModal: ${props.isSystemModal}`,
//     );
//     if (cancelClose) return;
//     if (!props.isSystemModal) {
//       store.closeModal();
//     }
//   };

//   // Modified to use pointer-events-none on non-interactive elements
//   const handleBackdropClick = (e: MouseEvent) => {
//     if (e.target === e.currentTarget) {
//       logger.debug('[BaseModal] handleBackdropClose');
//       handleModalClose();
//     }
//   };

//   const handleEscapeKey = (e: KeyboardEvent) => {
//     if (e.key === 'Escape' && !props.isSystemModal) {
//       handleModalClose();
//     }
//   };

//   const handleBeforeEnter = (el: Element) => {
//     logger.debug('Transition: onBeforeEnter');
//     (el as any).style.display = 'block';
//     window.addEventListener('keydown', handleEscapeKey);
//   };

//   const handleEnter = (el: Element, done: () => void) => {
//     logger.debug('Transition: onEnter');
//     const backdrop = el.querySelector('[data-modal-backdrop]');
//     const modal = el.querySelector('[data-modal-content]');

//     if (backdrop && modal) {
//       requestAnimationFrame(() => {
//         backdrop.classList.add('transition-all', 'ease-out');
//         modal.classList.add('transition-all', 'cubic-bezier(0.34,1.56,0.64,1)');

//         requestAnimationFrame(() => {
//           backdrop.classList.remove(...initialClasses.backdrop.split(' '));
//           backdrop.classList.add(...finalClasses.backdrop.split(' '));

//           modal.classList.remove(...initialClasses.modal.split(' '));
//           modal.classList.add(...finalClasses.modal.split(' '));
//         });
//       });

//       setTimeout(done, 700);
//     } else {
//       done();
//     }
//   };

//   const handleExit = (el: Element, done: () => void) => {
//     logger.debug('Transition: onExit');
//     const backdrop = el.querySelector('[data-modal-backdrop]');
//     const modal = el.querySelector('[data-modal-content]');

//     if (backdrop && modal) {
//       requestAnimationFrame(() => {
//         backdrop.classList.add('transition-all', 'ease-in-out');
//         modal.classList.add('transition-all', 'cubic-bezier(0.32,0,0.67,0)');

//         backdrop.classList.remove(...finalClasses.backdrop.split(' '));
//         backdrop.classList.add(...exitClasses.backdrop.split(' '));

//         modal.classList.remove(...finalClasses.modal.split(' '));
//         modal.classList.add(...exitClasses.modal.split(' '));
//       });

//       setTimeout(done, 700);
//     } else {
//       done();
//     }
//   };

//   const handleAfterExit = (el: Element) => {
//     logger.debug('Transition: onAfterExit');
//     (el as any).style.display = 'none';
//     window.removeEventListener('keydown', handleEscapeKey);
//   };

//   // const initialClasses = {
//   //   backdrop: 'opacity-0',
//   //   modal: `opacity-0 scale-50 -translate-y-24 rotate-12 sm:translate-y-0 sm:scale-50 duration-700`,
//   // };

//   // const finalClasses = {
//   //   backdrop: 'opacity-100',
//   //   modal: `opacity-100 scale-100 translate-y-0 rotate-0 sm:translate-y-0 sm:scale-100 duration-700`,
//   // };

//   // const exitClasses = {
//   //   backdrop: 'opacity-0 duration-700',
//   //   modal: `opacity-0 scale-125 translate-y-16 rotate-6 sm:translate-y-12 sm:scale-125 duration-700`,
//   // };

//   const initialClasses = {
//     backdrop: 'opacity-0',
//     modal: `opacity-0 scale-50 -translate-y-24 rotate-12 sm:translate-y-0 sm:scale-50 duration-700`,
//   };

//   const finalClasses = {
//     backdrop: 'opacity-100',
//     modal: `opacity-100 scale-100 translate-y-0 rotate-0 sm:translate-y-0 sm:scale-100 duration-700`,
//   };

//   const exitClasses = {
//     backdrop: 'opacity-0 duration-700',
//     modal: `opacity-0 scale-125 translate-y-16 rotate-6 sm:translate-y-12 sm:scale-125 duration-700`,
//   };

//   return (
//     <Show when={store.isModalOpen(props.key)}>
//       <Portal>
//         <Transition
//           appear={true}
//           onBeforeEnter={handleBeforeEnter}
//           onEnter={handleEnter}
//           onExit={handleExit}
//           onAfterExit={handleAfterExit}
//         >
//           <div
//             class="fixed inset-0 z-[50]"
//             role="dialog"
//             aria-modal="true"
//             aria-labelledby="modal-title"
//             aria-describedby={
//               props.description ? 'modal-description' : undefined
//             }
//           >
//             {/* Theme-aware backdrop */}
//             <div
//               data-modal-backdrop
//               class={`fixed inset-0 bg-background/95 not-dark:bg-background/95 backdrop-blur-[8px]
//                      transition-all duration-700 ease-out ${initialClasses.backdrop}
//                      motion-reduce:backdrop-blur-none`}
//               aria-hidden="true"
//               onClick={handleBackdropClick}
//             />

//             <div class="fixed inset-0 overflow-y-auto">
//               <div
//                 class="min-h-screen flex items-center justify-center p-4 text-center sm:p-0"
//                 onClick={handleBackdropClick}
//               >
//                 {/* Theme-aware modal content */}
//                 <div
//                   data-modal-content
//                   class={`relative transform overflow-hidden rounded-[30px]
//                          border-2 not-dark:border-neutral-700 px-4 pb-8 pt-4
//                          text-left shadow-2xl will-change-transform
//                          motion-safe:transition-all motion-safe:duration-700
//                          hover:shadow-[0_20px_60px_-15px_rgba(0,0,0,0.2)]
//                          not-dark:hover:shadow-[0_20px_60px_-15px_rgba(0,0,0,0.4)]
//                          sm:my-8 sm:w-full
//                          bg-white not-dark:bg-neutral-900
//                          text-foreground
//                          min-h-[50vh] md:min-h-[60vh]
//                          ${props.fullWidth == 'small' ? 'sm:max-w-4xl' : props.fullWidth == 'large' ? 'sm:max-w-[90%]' : 'sm:max-w-lg'}
//                          sm:p-6 ${initialClasses.modal}`}
//                   style={{
//                     'transform-origin': 'center 0px',
//                     'z-index': '101',
//                   }}
//                 >
//                   {/* Theme-aware gradient overlay */}
//                   <div
//                     class="absolute inset-0 bg-gradient-to-b from-background/50 via-transparent to-transparent
//                            not-dark:from-background/20
//                            pointer-events-none opacity-0 transition-opacity duration-500
//                            group-hover:opacity-100"
//                   />

//                   <Show when={!props.isSystemModal}>
//                     <div class="absolute right-2 top-2">
//                       <button
//                         onClick={handleModalClose}
//                         class="group rounded-lg p-2
//                                hover:bg-muted not-dark:hover:bg-muted/20
//                                active:scale-95 focus:outline-none focus:ring-2
//                                transition-all duration-200
//                                hover:rotate-90 hover:shadow-lg"
//                         aria-label="Close modal"
//                       >
//                         <img
//                           src="/assets/images/cancel_28dp_000_FILL0_wght400_GRAD0_opsz24.png"
//                           alt=""
//                           class="w-5 h-5 transition-transform group-hover:scale-110
//                                 not-dark:invert"
//                         />
//                       </button>
//                     </div>
//                   </Show>

//                   <div class="sm:flex sm:items-start relative z-10">
//                     <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
//                       <h3
//                         id="modal-title"
//                         class="text-xl text-center font-semibold leading-6
//                                text-foreground not-dark:text-foreground
//                                transition-colors duration-200"
//                       >
//                         {props.title}
//                       </h3>

//                       <Show when={props.description}>
//                         <p
//                           id="modal-description"
//                           class="mt-2 text-sm text-center
//                                  text-muted-foreground not-dark:text-muted-foreground
//                                  transition-colors duration-200"
//                         >
//                           {props.description}
//                         </p>
//                       </Show>

//                       <div class="mt-4 relative">
//                         <div class="group relative transition-all duration-300">
//                           {props.children}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Transition>
//       </Portal>
//     </Show>
//   );
// };
