interface Window {
  gtag: (command: string, action: string, params?: any) => void;
  dataLayer: any[]; // Optional: For use with Google Tag Manager
}

// TODO(pbirch): this is super rough so far, needs lots of love
export interface AnalyticsEvent {
  category: string;
  action: string;
  label?: string;
  value?: number;
  metadata?: Record<string, unknown>;
}

export class Analytics {
  private static instance: Analytics;

  #gtag?: any;

  constructor(window?: Window) {
    if (window && window !== undefined) {
      const w = window as any;
      if (w.gtag && w.gtag !== undefined) {
        this.#gtag = w.gtag;
        w.gtag('config', 'YOUR_TRACKING_ID');
        w.gtag('event', 'page_view');
      }
    }
  }

  static getInstance(window?: Window): Analytics {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics(window);
    }
    return Analytics.instance;
  }

  trackEvent(event: AnalyticsEvent) {
    console.log(
      `[Analytics][${this.#gtag}] Event[${event.action} / ${event.category}] ${event.label}:`,
      event.metadata,
    );
    if (this.#gtag == undefined) return;
    this.#gtag('event', event.action, {
      event_category: event.category,
      event_label: event.label,
      value: event.value,
      ...event.metadata,
    });
  }

  trackPageView(path: string) {
    if (this.#gtag == undefined) return;
    this.#gtag('config', process.env.GA_MEASUREMENT_ID!, {
      page_path: path,
    });
  }
}
