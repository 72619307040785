import { CacheMonitorModal } from './CacheMonitorModal.jsx';
import { Component, createEffect, createContext } from 'solid-js';
import { createRoot, ParentProps } from 'solid-js';
import { CreateModal } from './CreateModal.jsx';
import { PreviewModal } from './PreviewModal.jsx';
import { PurchaseModal } from './PurchaseModal.jsx';
import { WelcomeModal } from './WelcomeModal.jsx';
import { WorkingModal } from './WorkingModal.jsx';
import { ModalManagerStore } from './ModalManager.store.js';

// Create a context for the modal store
export const ModalStoreContext = createContext<ModalManagerStore>();

export const ModalStoreProvider: Component<ParentProps> = (props) => {
  const store = createRoot(() => ModalManagerStore.getInstance());

  return (
    <ModalStoreContext.Provider value={store}>
      {props.children}
    </ModalStoreContext.Provider>
  );
};

export const ModalManager: Component = () => {
  // Control body scroll when modals are open
  createEffect(() => {
    const store = ModalManagerStore.getInstance();
    const activeModal = store.getActiveModal();
    if (activeModal) {
      document.body.style.overflow = 'hidden';
      // Add padding right to prevent layout shift when scrollbar disappears
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    }
  });

  return (
    // Higher z-index than header
    <div class="fixed inset-0 z-[100] pointer-events-none">
      <CreateModal />
      <PreviewModal onClose={() => console.log('Close Modal Manager')} />
      <PurchaseModal
        onClose={() => console.log('Purchase close')}
        designId=""
      />
      <WelcomeModal onClose={() => console.log('Welcome close')} />
      <WorkingModal />
      <CacheMonitorModal onClose={() => console.log('Cache close')} />
    </div>
  );
};
