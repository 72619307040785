import {
  Accessor,
  createRoot,
  createSignal,
  createEffect,
  onMount,
  Setter,
} from 'solid-js';
import { isServer } from 'solid-js/web';
import { Logger } from '@repo/logger';
import { ModalId } from '../types.js';

export interface Modal {
  key?: ModalId;
  props?: Record<string, unknown>;
  onClose?: () => void;
}

export type MenuId =
  | 'main-menu'
  | 'user-menu'
  | 'project-menu'
  | 'element-menu';

export type ModalCommand =
  | {
      type: 'OPEN_MODAL';
      payload: { id: ModalId; props: Record<string, unknown> | undefined };
      onClose?: () => void;
    }
  | { type: 'CLOSE_MODAL' }
  | { type: 'TOGGLE_MENU'; payload: { id: MenuId } };

export interface Toast {
  message: string;
  type: 'success' | 'error';
}

export interface ModalState {
  activeModals: Modal[];
  activeMenus: Set<MenuId>;
  toast: Toast | null;
}

export const defaultModalState: ModalState = {
  activeModals: [],
  activeMenus: new Set(),
  toast: null,
};

const STORE_KEY = 'modal:state';

export class ModalManagerStore {
  #logger: Logger;
  static #instance: ModalManagerStore;

  #state!: Accessor<ModalState>;
  #setState!: Setter<ModalState>;
  #disposed = false;

  private constructor() {
    this.#logger = new Logger('ModalManagerStore');

    // Create signals and effects within a root
    const disposal = createRoot((dispose) => {
      const [state, setState] = createSignal<ModalState>(defaultModalState);
      this.#state = state;
      this.#setState = setState;

      const [isLoaded, setIsLoaded] = createSignal(false);

      // Load preferences from localStorage on client-side mount
      onMount(() => {
        if (isServer) return;
        try {
          const stored = localStorage.getItem(STORE_KEY);
          if (stored) {
            const parsedState = JSON.parse(stored) as ModalState;
            setState(parsedState);
          }
        } catch (error) {
          console.error('Error loading modal state from localStorage:', error);
        }
        setIsLoaded(true);
      });

      // Save to localStorage whenever data changes
      createEffect(() => {
        if (!isServer && isLoaded()) {
          try {
            localStorage.setItem(STORE_KEY, JSON.stringify(state()));
          } catch (error) {
            console.error('Error saving modal state to localStorage:', error);
          }
        }
      });

      // Return the disposal function
      return dispose;
    });

    // Store disposal function for cleanup
    Object.defineProperty(this, 'dispose', {
      value: () => {
        if (!this.#disposed) {
          disposal();
          this.#disposed = true;
        }
      },
      writable: false,
    });
  }

  public static getInstance(): ModalManagerStore {
    if (!ModalManagerStore.#instance) {
      ModalManagerStore.#instance = new ModalManagerStore();
    }
    return ModalManagerStore.#instance;
  }

  // Add dispose method to cleanup type
  public dispose(): void {
    (this as any).dispose?.();
  }

  private updateState(updates: Partial<ModalState>): void {
    const newState: ModalState = {
      ...this.#state(),
      ...updates,
    };

    this.#setState(newState);
  }

  // Rest of the methods remain the same
  handleCommand(command: ModalCommand): void {
    this.#logger.info('Handling UI command', { type: command.type });

    try {
      switch (command.type) {
        case 'OPEN_MODAL': {
          const modal: Modal = {
            key: command.payload.id,
            props: command.payload.props || {},
            onClose: command.onClose,
          };
          const currentModals = this.#state().activeModals;
          this.#logger.info('Opening modal', {
            modalId: modal.key,
            curr: currentModals,
          });
          currentModals.push(modal);
          this.updateState({ activeModals: currentModals });
          break;
        }

        case 'CLOSE_MODAL': {
          this.#logger.debug('Close active modal');
          const closingModal = this.#state().activeModals.slice(-1)[0];
          closingModal?.onClose?.();
          const activeModals = this.#state().activeModals.slice(0, -1);
          this.updateState({ activeModals });
          break;
        }

        case 'TOGGLE_MENU': {
          const activeMenus = new Set(this.#state().activeMenus);
          if (activeMenus.has(command.payload.id)) {
            activeMenus.delete(command.payload.id);
          } else {
            activeMenus.add(command.payload.id);
          }
          this.#logger.debug('Toggling menu', { activeMenus });
          this.updateState({ activeMenus });
          break;
        }
      }
    } catch (error) {
      this.#logger.error('UI command failed', error as Error, {
        commandType: command.type,
      });
    }
  }

  openModal(
    id: ModalId,
    props?: Record<string, unknown>,
    onClose?: () => void,
  ) {
    this.handleCommand({ type: 'OPEN_MODAL', payload: { id, props }, onClose });
  }

  closeModal() {
    this.handleCommand({ type: 'CLOSE_MODAL' });
  }

  toggleMenu(id: MenuId) {
    this.handleCommand({ type: 'TOGGLE_MENU', payload: { id } });
  }

  getActiveModal() {
    const modals = this.#state().activeModals;
    return modals.length > 0 ? modals[modals.length - 1] : undefined;
  }

  isModalOpen(id: ModalId) {
    const modals = this.#state().activeModals;
    const modal = modals[modals.length - 1];
    return modal?.key === id;
  }

  isMenuOpen(id: MenuId) {
    const menus = this.#state().activeMenus;
    return menus.has(id);
  }

  getState(): Accessor<ModalState> {
    return this.#state;
  }

  resetState(): void {
    this.updateState(defaultModalState);
  }
}
