import { Managers } from './ActionButtonTypes';
import { isServer } from 'solid-js/web';

export async function initializeManagers(): Promise<Managers | null> {
  if (isServer) return null;

  try {
    const [
      { DeviceManager },
      { SoundManager },
      { GestureManager },
      { ParticleManager },
    ] = await Promise.all([
      import('../../managers/DeviceManager'),
      import('../../managers/SoundManager'),
      import('../../managers/GestureManager'),
      import('~/utils/ParticleManager'),
    ]);

    return {
      device: DeviceManager.getInstance(),
      sound: SoundManager.getInstance(),
      gesture: GestureManager.getInstance(),
      particle: ParticleManager.getInstance(),
    };
  } catch (error) {
    console.error('Failed to initialize managers:', error);
    return null;
  }
}
