import {
  createSignal,
  createEffect,
  onMount,
  onCleanup,
  Show,
  Setter,
} from 'solid-js';
import { Alert, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';

import { Tabs, TabsList, TabsTrigger, TabsContent } from '~/components/ui/tabs';
import { IDB, imagecache, filecache } from '@repo/database';
import { Database } from '@repo/state-manager';

import { ImageCacheTable } from './ImageCacheTable.jsx';
import { FileCacheTable } from './FileCacheTable.jsx';
import { formatBytes, formatDate } from '@repo/shared';

interface CacheMonitorProps {
  onClose: () => void;
}

interface BaseCacheEntry {
  key: string;
  type: string;
  size: number;
  timestamp: number;
  expiresAt: number;
}

interface ImageCacheEntry extends BaseCacheEntry {
  fileExt?: {
    width: number;
    height: number;
    aspectRatio: number;
  };
  originalType: string;
  originalSize?: number;
  lastAccess?: number;
}

interface FileCacheEntry extends BaseCacheEntry {
  // Add any file-specific properties here
}

interface CacheState<T extends BaseCacheEntry> {
  entries: T[];
  lastModified: Record<string, number>;
  lastFetch: number;
}

interface CacheVersionInfo {
  imageCacheVersion: number;
  fileCacheVersion: number;
  timestamp: number;
}

export const CacheMonitor = (props: CacheMonitorProps) => {
  const [activeTab, setActiveTab] = createSignal('imagecache');
  const [fileCache, setFileCache] = createSignal<CacheState<FileCacheEntry>>({
    entries: [],
    lastModified: {},
    lastFetch: 0,
  });
  const [imageCache, setImageCache] = createSignal<CacheState<ImageCacheEntry>>(
    {
      entries: [],
      lastModified: {},
      lastFetch: 0,
    },
  );
  const [error, setError] = createSignal<string | null>(null);
  const [stats, setStats] = createSignal<filecache.CacheStats | null>(null);
  const [cacheVersion, setCacheVersion] = createSignal<CacheVersionInfo>({
    imageCacheVersion: 0,
    fileCacheVersion: 0,
    timestamp: 0,
  });

  let db: IDB;
  let fc: filecache.DefaultFileCache<unknown>;
  let ic: imagecache.ImageCache;
  let refreshInterval: NodeJS.Timeout;
  let isInitialLoad = true;

  const transformFileCacheData = (
    cacheData: filecache.CacheMetadata<unknown>[],
    fileData: filecache.FileMetadata<unknown>[],
  ): FileCacheEntry[] => {
    const fileDataMap = new Map(fileData.map((f) => [f.key, f]));

    return cacheData.map((entry) => ({
      key: entry.key,
      type: entry.type,
      size: entry.size,
      timestamp: entry.timestamp,
      expiresAt: entry.expiresAt ?? Date.now() + 24 * 60 * 60 * 1000,
    }));
  };

  const transformImageCacheData = (
    cacheData: filecache.CacheMetadata<unknown>[],
    fileData: filecache.FileMetadata<imagecache.ImageFileExt>[],
  ): ImageCacheEntry[] => {
    const fileDataMap = new Map(fileData.map((f) => [f.key, f]));

    return cacheData.map((entry) => {
      const fileMetadata = fileDataMap.get(entry.key);
      return {
        key: entry.key,
        type: entry.type,
        originalType: entry.originalType,
        size: entry.size,
        originalSize: entry.originalSize,
        timestamp: entry.timestamp,
        expiresAt: entry.expiresAt ?? Date.now() + 24 * 60 * 60 * 1000,
        lastAccess: entry.lastAccess,
        fileExt: fileMetadata?.fileExt,
      };
    });
  };

  const updateCacheEntries = <T extends BaseCacheEntry>(
    currentState: CacheState<T>,
    newEntries: T[],
    setCacheState: Setter<CacheState<T>>,
  ) => {
    if (isInitialLoad) {
      setCacheState({
        entries: newEntries,
        lastModified: Object.fromEntries(
          newEntries.map((entry) => [entry.key, entry.timestamp]),
        ),
        lastFetch: Date.now(),
      });
      isInitialLoad = false;
      return;
    }

    const currentEntriesMap = new Map(
      currentState.entries.map((entry) => [entry.key, entry]),
    );
    const newEntriesMap = new Map(
      newEntries.map((entry) => [entry.key, entry]),
    );

    let hasChanges = false;
    const updatedEntries = [...currentState.entries];
    const updatedLastModified = { ...currentState.lastModified };

    newEntries.forEach((newEntry) => {
      const currentEntry = currentEntriesMap.get(newEntry.key);
      const lastKnownTimestamp = currentState.lastModified[newEntry.key];

      if (!currentEntry || newEntry.timestamp !== lastKnownTimestamp) {
        hasChanges = true;
        const index = currentEntry
          ? updatedEntries.findIndex((e) => e.key === newEntry.key)
          : -1;
        if (index !== -1) {
          updatedEntries[index] = newEntry;
        } else {
          updatedEntries.push(newEntry);
        }
        updatedLastModified[newEntry.key] = newEntry.timestamp;
      }
    });

    // Check for deleted entries
    const deletedKeys = [...currentEntriesMap.keys()].filter(
      (key) => !newEntriesMap.has(key),
    );
    if (deletedKeys.length > 0) {
      hasChanges = true;
    }

    if (hasChanges) {
      const updatedEntriesList = updatedEntries.filter((entry) =>
        newEntriesMap.has(entry.key),
      );

      Object.keys(updatedLastModified).forEach((key) => {
        if (!newEntriesMap.has(key)) {
          delete updatedLastModified[key];
        }
      });

      setCacheState({
        entries: updatedEntriesList,
        lastModified: updatedLastModified,
        lastFetch: Date.now(),
      });
    }
  };

  const checkCacheVersions = async () => {
    try {
      const [imageVersion, fileVersion] = await Promise.all([
        ic.getVersion(),
        fc.getVersion(),
      ]);

      const currentVersion = cacheVersion();
      const newVersion = {
        imageCacheVersion: imageVersion,
        fileCacheVersion: fileVersion,
        timestamp: Date.now(),
      };

      if (
        currentVersion.imageCacheVersion !== imageVersion ||
        currentVersion.fileCacheVersion !== fileVersion
      ) {
        setCacheVersion(newVersion);
        return true;
      }

      return false;
    } catch (err) {
      console.error('Failed to check cache versions:', err);
      return true; // Load data on error to be safe
    }
  };

  const loadData = async (force = false) => {
    try {
      const shouldUpdate = force || (await checkCacheVersions());
      if (!shouldUpdate) {
        return;
      }

      const database = Database.getInstance();
      await database.initialize();
      db = database.db;
      fc = database.fileCache;
      ic = database.imageCache;

      const [fileCacheData, imageCacheData] = await Promise.all([
        fc.getAllCacheMetadata(),
        ic.getAllCacheMetadata(),
      ]);

      const [fileMetadata, imageMetadata] = await Promise.all([
        fc.getAllFileMetadata(),
        ic.getAllFileMetadata(),
      ]);

      const transformedFileData = transformFileCacheData(
        fileCacheData,
        fileMetadata,
      );
      const transformedImageData = transformImageCacheData(
        imageCacheData,
        imageMetadata,
      );

      updateCacheEntries(fileCache(), transformedFileData, setFileCache);
      updateCacheEntries(imageCache(), transformedImageData, setImageCache);

      const cacheStats = await ic.getStats();
      setStats(cacheStats);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : 'Failed to load cache data',
      );
    }
  };

  const clearCacheItem = async (key: string) => {
    try {
      const type = activeTab();
      if (type === 'filecache') {
        await fc.deleteCacheEntry(key);
      } else if (type === 'imagecache') {
        await ic.deleteCacheEntry(key);
      }
      await loadData(true);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : 'Failed to clear cache item',
      );
    }
  };

  const clearCache = async () => {
    try {
      const type = activeTab();
      if (type === 'filecache') {
        await fc.clear();
        setFileCache({ entries: [], lastModified: {}, lastFetch: Date.now() });
      } else if (type === 'imagecache') {
        await ic.clear();
        setImageCache({ entries: [], lastModified: {}, lastFetch: Date.now() });
      }
      isInitialLoad = true;
      await loadData(true);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to clear cache');
    }
  };

  const resetDB = async () => {
    try {
      await db.resetDB();
      setFileCache({ entries: [], lastModified: {}, lastFetch: Date.now() });
      setImageCache({ entries: [], lastModified: {}, lastFetch: Date.now() });
      isInitialLoad = true;
      await loadData(true);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : 'Failed to delete database',
      );
    }
  };

  onMount(() => {
    loadData(true);
    refreshInterval = setInterval(() => loadData(false), 10000);
  });

  onCleanup(() => {
    clearInterval(refreshInterval);
  });

  return (
    <>
      <Show when={error()}>
        <Alert variant="destructive" class="mb-4">
          <AlertTitle>{error()}</AlertTitle>
        </Alert>
      </Show>

      <Show when={stats()}>
        <div class="grid grid-cols-4 gap-4 mb-4">
          <div class="rounded-lg bg-secondary p-4">
            <div class="text-sm font-medium">Total Items</div>
            <div class="text-xl font-bold">{stats()?.totalItems}</div>
          </div>
          <div class="rounded-lg bg-secondary p-4">
            <div class="text-sm font-medium">Total Size</div>
            <div class="text-xl font-bold">
              {formatBytes(stats()?.totalSize ?? 0)}
            </div>
          </div>
          <div class="rounded-lg bg-secondary p-4">
            <div class="text-sm font-medium">Space Usage</div>
            <div class="text-xl font-bold">
              {stats()?.spaceUtilization.toFixed(1)}%
            </div>
          </div>
          <div class="rounded-lg bg-secondary p-4">
            <div class="text-sm font-medium">Compress Ratio</div>
            <div class="text-xl font-bold">
              {((stats()?.compressionRatio ?? 1) * 100).toFixed(1)}%
            </div>
          </div>
        </div>
      </Show>

      <Tabs defaultValue={activeTab()} onChange={setActiveTab}>
        <div class="flex justify-between items-center mb-4">
          <TabsList>
            <TabsTrigger value="imagecache">Image Cache</TabsTrigger>
            <TabsTrigger value="filecache">File Cache</TabsTrigger>
          </TabsList>
          <Button onClick={clearCache} variant="destructive" size="sm">
            Clear All
          </Button>
          <Button onClick={resetDB} variant="destructive" size="sm">
            Reset DB
          </Button>
        </div>

        <TabsContent value="imagecache">
          <div class="space-y-4 h-[500px] overflow-auto">
            <ImageCacheTable
              data={imageCache().entries}
              onClearItem={clearCacheItem}
              pageSize={5}
            />
          </div>
        </TabsContent>

        <TabsContent value="filecache">
          <div class="space-y-4 h-[500px] overflow-auto">
            <FileCacheTable
              data={fileCache().entries}
              onClearItem={clearCacheItem}
            />
          </div>
        </TabsContent>
      </Tabs>
    </>
  );
};
